import axios from "axios";
// Next we make an 'instance' of it
const axiosConfig = axios.create({
  // .. where we make our configurations
  baseURL: "https://api.example.com",
  withCredentials: true,
});



axiosConfig.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.status === 401) {
      localStorage.removeItem("user");
      window.location.href = "/login";
    }
    return Promise.reject(JSON.stringify(error?.response?.data));
  }
);

export default axiosConfig;
