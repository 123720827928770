import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { logoutUserRoute,resetPasswordLinkUserRoute,verifyTokenUserRoutes,resetPasswordRoutes, authUserRoute } from "./../../../Api/apiroutehelper";
import axiosConfig from "./../../../Api/axiosConfig";

export const authUser = createAsyncThunk(
  "authUserAuth",
  async (data, state) => {
    let routeToCall = authUserRoute();
    const response = await axiosConfig.put(routeToCall, data);
    return response;
  }
);
export const logoutUser = createAsyncThunk(
  "logoutUserAuth",
  async (data, state) => {
    let routeToCall = logoutUserRoute();
    const response = await axiosConfig.put(routeToCall, data.data);
    return response;
  }
);
export const sendResetPasswordLink = createAsyncThunk(
  "sendResetPasswordLink",
  async (data, state) => {
    let routeToCall = resetPasswordLinkUserRoute();
    const response = await axiosConfig.put(routeToCall, data);
    return response;
  }
);
export const verifyToken = createAsyncThunk(
  "verifyToken",
  async (token, state) => {
    let routeToCall = verifyTokenUserRoutes(token);
    const response = await axiosConfig.get(routeToCall, token);
    return response;
  }
);
export const resetPassword = createAsyncThunk(
  "resetPassword",
  async (data, state) => {
    let routeToCall = resetPasswordRoutes();
    const response = await axiosConfig.put(routeToCall, data);
    return response;
  }
);
export const authSlice = createSlice({
  name: "auth",
  initialState: {
    authUserDataStatus: "idle",
    authUserData: {},
    sendMailStatus:"idle",
    verifyTokenStatus:"idle",
    resetPasswordStatus:"idle",
    notify: null,
    error: null,
  },
  reducers: {
    clearNotify(state, action) {
      state.notify = null;
    },
    clearErrorData(state, action) {
      state.error = null;
    },
    getAuthUserDetails(state, action) {
      state.authUserData = JSON.parse(localStorage.getItem("user"));
    },
  },
  extraReducers: {
    [authUser.pending]: (state) => {
      state.authUserDataStatus = "loading";
    },
    [authUser.fulfilled]: (state, action) => {
      state.authUserData = action.payload.data;
      localStorage.setItem("user", JSON.stringify(action.payload.data));
      state.authUserDataStatus = "succeeded";
      state.notify = "succeeded";
    },
    [authUser.rejected]: (state, action) => {
      state.authUserDataStatus = "failed";
      state.error = action?.error?.message
        ? JSON.parse(action?.error?.message)?.message
        : "Something went Wrong, Please Try again!";
    },
    [sendResetPasswordLink.pending]: (state) => {
      state.sendMailStatus = "loading";
    },
    [sendResetPasswordLink.fulfilled]: (state, action) => {
      
      
      state.sendMailStatus = "succeeded";
      state.notify = "succeeded";
    },
    [sendResetPasswordLink.rejected]: (state, action) => {
      state.sendMailStatus = "failed";
      state.error = action?.error?.message
        ? JSON.parse(action?.error?.message)?.message
        : "Something went Wrong, Please Try again!";
    },
    [verifyToken.pending]: (state) => {
      state.verifyTokenStatus = "loading";
    },
    [verifyToken.fulfilled]: (state, action) => {
      state.verifyTokenStatus = "succeeded";
    },
    [verifyToken.rejected]: (state, action) => {
      state.verifyTokenStatus = "failed";
      state.error = action?.error?.message
        ? JSON.parse(action?.error?.message)?.message
        : "Something went Wrong, Please Try again!";
    },
    [resetPassword.pending]: (state) => {
      state.resetPasswordStatus = "loading";
    },
    [resetPassword.fulfilled]: (state, action) => {
      
      
      state.resetPasswordStatus = "succeeded";
      state.notify = "succeeded";
    },
    [resetPassword.rejected]: (state, action) => {
      state.resetPasswordStatus = "failed";
      state.error = action?.error?.message
        ? JSON.parse(action?.error?.message)?.message
        : "Something went Wrong, Please Try again!";
    },
    [logoutUser.pending]: (state) => {
      state.authUserDataStatus = "loading";
    },
    [logoutUser.fulfilled]: (state, action) => {
      localStorage.removeItem("user");
      
      state.authUserDataStatus = {};
    },
    [logoutUser.rejected]: (state, action) => {
      state.authUserDataStatus = "failed";
      state.error = action?.error?.message
        ? JSON.parse(action?.error?.message)?.message
        : "Something went Wrong, Please Try again!";
    },
  },
});

export const { clearNotify, clearErrorData,getAuthUserDetails } = authSlice.actions;

export default authSlice.reducer;
