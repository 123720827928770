import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getProducts,
  saveProduct,
  updateProduct,
  deleteProduct,
} from "../../Store/Reducers/Product/productSlice";
import {
  Tag,
  Card,
  Space,
  Table,
  Input,
  Breadcrumb,
  Button,
  Form,
  Row,
  Col,
  Upload,
  Select,
  InputNumber,
  Image,
  Popconfirm,
  Checkbox,
  message,
} from "antd";
import {
  HomeOutlined,
  UserOutlined,
  EditFilled,
  DeleteFilled,
  UploadOutlined,
  SearchOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import "./product.scss";
import { randomNumber } from "../../Util/commonUtility";
import Highlighter from "react-highlight-words";
import { useNavigate } from "react-router-dom";
// const { Meta } = Card;
const { Option } = Select;
const formItemLayout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 14,
  },
};
const ProductContainer = (props) => {
  let dispatch = useDispatch();
  const navigate = useNavigate();
  let [productForm] = Form.useForm();
  const {
    productDataStatus,
    productData,
    saveProductStatus,
    deleteProductStatus,
    updateProductStatus,
  } = useSelector((state) => state.products);

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters, confirm, dataIndex) => {
    clearFilters();
    setSearchText("");
    handleSearch("", confirm, dataIndex);
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              handleReset(clearFilters, confirm, dataIndex);
            }}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const columns = [
    {
      title: "Sr Number",
      dataIndex: "product_id",
    },
    {
      title: "Brand",
      dataIndex: "brand",
      ...getColumnSearchProps("brand"),
    },

    {
      title: "Description",
      dataIndex: "description",
    },
    {
      title: "Type",
      dataIndex: "type",
    },
    {
      title: "Category",
      dataIndex: "category",
    },
    {
      title: "Price",
      dataIndex: "price",
    },
    {
      title: "Regular Price",
      dataIndex: "regularPrice",
    },
    {
      title: "Image",
      dataIndex: "image",
      render: (text) => (
        <Image width={100} src={`${process.env.REACT_APP_API_URL}${text}`} />
      ),
    },
    {
      title: "Action",
      render: (_, record) => (
        <Space size="middle">
          <a>
            <EditFilled
              onClick={() => {
                editProduct(record);
              }}
            />
          </a>
          <Popconfirm
            title="Are you sure to delete this product?"
            onConfirm={() => {
              deleteProductHandle(record._id);
            }}
            onCancel={() => {}}
            okText="Yes"
            cancelText="No"
          >
            <a>
              <DeleteFilled />
            </a>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  let [showProductForm, setShowProductForm] = useState({
    isOpen: false,
    editProductData: null,
    isEdit: false,
  });
  let [imageFile, setImageFile] = useState(false);
  let [thumbFile, setThumbFile] = useState(false);

  useEffect(() => {
    if (productDataStatus === "idle") {
      dispatch(getProducts());
    }
  }, [dispatch, productDataStatus]);

  useEffect(() => {
    if (
      saveProductStatus === "succeeded" ||
      updateProductStatus === "succeeded"
    ) {
      dispatch(getProducts());
      setShowProductForm({ isOpen: false, isEdit: false, editProductData: {} });
    }
  }, [dispatch, saveProductStatus, updateProductStatus]);

  const addProduct = () => {
    setShowProductForm({ isOpen: true, isEdit: false, editProductData: {} });
    setDisableSubmitButton(false);
  };
  const uploadProductCSV = () => {
    // Navigate
    navigate("/product/upload");
  };
  const onFinish = (data) => {

    const formData = new FormData();
    formData.append("thumbnail", thumbFile);
    formData.append("image", imageFile);

    formData.append("brand", data.brand);
    formData.append("name", data.name);
    formData.append("type", data.type);
    formData.append("category", data.category);
    formData.append("size", data.size);
    formData.append("quantity", data.quantity);
    formData.append("metric", data.metric);
    formData.append("price", data.price);
    formData.append("regularPrice", data.regularPrice);
    formData.append("pricePer100", data.pricePer100);
    formData.append("description", data.description);
    formData.append("groupSize", data.groupSize);
    formData.append("isAddToCurrentStores", data.isAddToCurrentStores);
    try {
      if (showProductForm.isEdit) {
        formData.append("_id", data._id);
        formData.append("srNumber", data.srNumber);
        dispatch(updateProduct(formData));
      } else {
        formData.append("srNumber", randomNumber());
        dispatch(saveProduct(formData));
      }
      setDisableSubmitButton(true);
    } catch (error) {
      
    }
  };
  const onFinishFailed = () => {
    
  };
  const goBack = () => {
    setShowProductForm({ isOpen: false, editProductData: {}, isEdit: false });
    setDisableSubmitButton(false);
  };
  const editProduct = (data) => {
    
    setShowProductForm({ isOpen: true, editProductData: data, isEdit: true });
    setDisableSubmitButton(false);
  };
  const deleteProductHandle = (id) => {
    dispatch(deleteProduct(id));
    
  };
  const checkSize = (size) => {
    return size / 1024 / 1024 < 2;
  };
  const dummyRequest = ({ file, onSuccess, onError }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
    
  };
  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = checkSize(file.size);
    if (!isLt2M) {
      message.error("Image must smaller than 1MB!");
    }
    return isJpgOrPng && isLt2M;
  };
 

  return (
    <Card
      title={
        <Space
          direction="horizontal"
          style={{ width: "100%", justifyContent: "space-between" }}
        >
          <Breadcrumb>
            <Breadcrumb.Item>
              <HomeOutlined />
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <a href="#" onClick={goBack}>
                <UserOutlined />
                <span>Product </span>
              </a>
            </Breadcrumb.Item>
            {showProductForm?.isOpen && (
              <Breadcrumb.Item>
                <b>
                  <u>{showProductForm?.editProductData?.product_id}</u>
                </b>
              </Breadcrumb.Item>
            )}
            {showProductForm.isOpen ? (
              showProductForm.isEdit ? (
                <Breadcrumb.Item>Edit</Breadcrumb.Item>
              ) : (
                <Breadcrumb.Item>Add</Breadcrumb.Item>
              )
            ) : (
              <Breadcrumb.Item>List</Breadcrumb.Item>
            )}
          </Breadcrumb>
          {!showProductForm?.isEdit && (
            <Space>
              <Button type="primary" onClick={uploadProductCSV}>
                Upload CSV
              </Button>
              <Button type="primary" onClick={addProduct}>
                Add
              </Button>
            </Space>
          )}
        </Space>
      }
    >
      {showProductForm?.isOpen ? (
        <>
          <Form
            name={productForm}
            {...formItemLayout}
            
            initialValues={showProductForm?.editProductData}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Row>
              <Col span={12}>
                <Form.Item name="srNumber" label="Serial Number" hidden={true}>
                  <Input />
                </Form.Item>
                <Form.Item
                  name="brand"
                  label="Brand Name"
                  rules={[
                    {
                      required: true,
                      message: "Please input brand name",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="description"
                  label="Description"
                  rules={[
                    {
                      required: true,
                      message: "Please input description",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item
                  name="type"
                  label="Product Type"
                  rules={[
                    {
                      required: true,
                      message: "Please input type",
                    },
                  ]}
                >
                  <Select placeholder="Type">
                    <Option value="milk"> Milk </Option>
                    <Option value="yogurt"> Yogurt </Option>
                    <Option value="cheese"> Cheese </Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="category"
                  label="Category"
                  rules={[
                    {
                      required: true,
                      message: "Please input Cateogory",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item
                  name="size"
                  label="Size"
                  rules={[
                    {
                      required: true,
                      message: "Please input Size",
                    },
                    {
                      type: "number",
                      message: "Please input only number",
                    },
                  ]}
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    min={1}
                    addonAfter={
                      <Form.Item
                        name="metric"
                        label="Metric"
                        noStyle
                        rules={[
                          {
                            required: true,
                            message: "Please select Metric",
                          },
                        ]}
                      >
                        <Select placeholder="Metric">
                          <Option value="ml"> ml </Option>
                          <Option value="L"> L </Option>
                          <Option value="g"> g </Option>
                          <Option value="kg"> kg</Option>
                        </Select>
                      </Form.Item>
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                {" "}
                <Form.Item
                  name="groupSize"
                  label="Group Size"
                  rules={[
                    {
                      required: true,
                      message: "Please input Group Size",
                    },
                  ]}
                >
                  <Select placeholder="Group Size">
                    <Option value="Small"> Small </Option>
                    <Option value="Medium"> Medium </Option>
                    <Option value="Large"> Large </Option>
                    <Option value="X-Large"> X-Large </Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item
                  name="price"
                  label="Price"
                  rules={[
                    {
                      required: true,
                      message: "Please input Price",
                    },
                    {
                      type: "number",
                      message: "Please input only number",
                    },
                  ]}
                >
                  <InputNumber
                    min={1}
                    style={{ width: "100%" }}
                    disabled={showProductForm?.isEdit}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="regularPrice"
                  label="Regular Price"
                  rules={[
                    {
                      required: true,
                      message: "Please input Regular Price",
                    },
                    {
                      type: "number",
                      message: "Please input only number",
                    },
                  ]}
                >
                  <InputNumber
                    min={1}
                    style={{ width: "100%" }}
                    disabled={showProductForm?.isEdit}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item
                  name="quantity"
                  label="Quantity"
                  rules={[
                    {
                      required: true,
                      message: "Please input quantity",
                    },
                    {
                      type: "number",
                      message: "Please input only number",
                    },
                  ]}
                >
                  <InputNumber min={1} style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="pricePer100"
                  label="Price Per 100"
                  rules={[
                    {
                      required: true,
                      message: "Please input Price Per 100",
                    },
                  ]}
                >
                  <Input disabled={showProductForm?.isEdit} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}></Col>
            </Row>

            <Row>
              <Col span={12}>
                <Form.Item
                  name="image"
                  label="Image"
                  extra="File formats: JPEG, PNG, GIF (max size 1MB)"
                >
                  <Upload
                    name="image"
                    accept="image/png, image/jpeg"
                    listType="picture"
                    maxCount={1}
                    beforeUpload={beforeUpload}
                    customRequest={dummyRequest}
                    onChange={(fileList) => {
                      setImageFile(fileList.file.originFileObj);
                    }}
                  >
                    <Button>
                      <UploadOutlined /> Select image
                    </Button>
                  </Upload>
                  {showProductForm?.isEdit && (
                    <Image
                      width={100}
                      src={`${process.env.REACT_APP_API_URL}${showProductForm.editProductData.image}`}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="thumbnail"
                  label="Thumbnail"
                  extra="File formats: JPEG, PNG, GIF (max size 1MB)"
                  
                >
                  <Upload
                    name="thumbnail"
                    listType="picture"
                    accept="image/png, image/jpeg"
                    maxCount={1}
                    beforeUpload={beforeUpload}
                    customRequest={dummyRequest}
                    onChange={(fileList) => {
                      setThumbFile(fileList.file.originFileObj);
                    }}
                  >
                    <Button>
                      <UploadOutlined /> Select thumbnail
                    </Button>
                  </Upload>
                  {showProductForm?.isEdit && (
                    <Image
                      width={100}
                      src={`${process.env.REACT_APP_API_URL}${showProductForm.editProductData.thumbnail}`}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>

            {/* <Row>
              <Col span={12}></Col>
              <Col span={12}></Col>
            </Row> */}

            {!showProductForm?.isEdit ? (
              <Col span={24}>
                {/* <>Whould you like to add this product to existing stores?</> */}
                <Form.Item
                  name="isAddToCurrentStores"
                  label="Add this product to all store ?"
                  valuePropName="checked"
                  extra="Whould you like to add this new product to all existing stores ?"
                  tooltip={{
                    title:
                      "Whould you like to add this new product to all existing stores ?",
                    icon: <InfoCircleOutlined />,
                  }}
                >
                  <Checkbox defaultChecked />
                </Form.Item>
              </Col>
            ) : (
              <Form.Item name="_id" label="id" hidden={true}>
                <Input />
              </Form.Item>
            )}

            <Space
              direction="horizontal"
              style={{ width: "100%", justifyContent: "space-between" }}
            >
              <Button type="primary" onClick={goBack}>
                Back
              </Button>
              <Button
                disabled={disableSubmitButton}
                type="primary"
                htmlType="submit"
              >
                {showProductForm.isEdit ? "Edit" : "Submit"}
              </Button>
            </Space>
          </Form>
        </>
      ) : (
        <>
          {productData?.length > 0 ? (
            <Table columns={columns} dataSource={productData} rowKey={`_id`} />
          ) : (
            <>No data found</>
          )}{" "}
        </>
      )}
    </Card>
  );
};

export default ProductContainer;
