import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getProductsRoute,
  saveProductRoute,
  deleteProductRoute,
  updateProductRoute,
  uploadProductCSVRoute,
  getImportCSVDataRoute,
  editSingleProductByCSVRoute,
} from "./../../../Api/apiroutehelper";
import axiosConfig from "./../../../Api/axiosConfig";
export const saveProduct = createAsyncThunk(
  "saveProduct",
  async (data, state) => {
    let routeToCall = saveProductRoute();
    
    const response = await axiosConfig.post(routeToCall, data, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });

    return response.data;
  }
);
export const uploadProductCSV = createAsyncThunk(
  "uploadProductCSV",
  async (data, state) => {
    let routeToCall = uploadProductCSVRoute();
    const response = await axiosConfig.post(routeToCall, data, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
    return response.data;
  }
);
export const getImportCSVData = createAsyncThunk(
  "getImportCSVData",
  async (data, state) => {
    let routeToCall = getImportCSVDataRoute();
    const response = await axiosConfig.get(routeToCall, data);
    return response.data;
  }
);
export const updateProduct = createAsyncThunk(
  "updateProduct",
  async (data, state) => {
    let routeToCall = updateProductRoute(data.get("_id"));
    data.delete("_id");
    const response = await axiosConfig.put(routeToCall, data, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
    return response.data;
  }
);
export const editSingleProductByCSV = createAsyncThunk(
  "editSingleProductByCSV",
  async (data, state) => {
    let routeToCall = editSingleProductByCSVRoute(data._id);
    const response = await axiosConfig.put(routeToCall, data);
    return response.data;
  }
);
export const getProducts = createAsyncThunk(
  "getProducts",
  async (data, state) => {
    let routeToCall = getProductsRoute();
    const response = await axiosConfig.get(routeToCall, data);
    return response.data;
  }
);
export const deleteProduct = createAsyncThunk(
  "deleteProduct",
  async (id, state) => {
    let routeToCall = deleteProductRoute(id);
    const response = await axiosConfig.delete(routeToCall);
    let resData = {
      response: response.data,
      deletedId: id,
    };
    return resData;
  }
);
export const productSlice = createSlice({
  name: "products",
  initialState: {
    productDataStatus: "idle",
    productData: {},
    saveProductStatus: "idle",
    deleteProductStatus: "idle",
    updateProductStatus: "idle",
    uploadProductCSVStatus: "idle",
    getImportDataStatus: "idle",
    editSingleProductByCSVStatus: "idle",
    getImportData: {},
    error: null,
    notify: null,
  },
  reducers: {
    clearNotify(state, action) {
      state.notify = null;
    },
    clearErrorData(state, action) {
      state.error = null;
    },
  },
  extraReducers: {
    [saveProduct.pending]: (state) => {
      state.saveProductStatus = "loading";
    },
    [saveProduct.fulfilled]: (state, action) => {
      
      state.saveProductStatus = "succeeded";
      state.notify = "Saved";
    },
    [saveProduct.rejected]: (state, action) => {
      state.saveProductStatus = "failed";
      state.error = action?.error?.message
        ? JSON.parse(action?.error?.message)?.message
        : "Something went Wrong, Please Try again!";
    },
    [updateProduct.pending]: (state) => {
      state.updateProductStatus = "loading";
    },
    [updateProduct.fulfilled]: (state, action) => {
      state.updateProductStatus = "succeeded";
      state.notify = "Updated";
    },
    [updateProduct.rejected]: (state, action) => {
      state.updateProductStatus = "failed";
      state.error = action?.error?.message
        ? JSON.parse(action?.error?.message)?.message
        : "Something went Wrong, Please Try again!";
    },
    [editSingleProductByCSV.pending]: (state) => {
      state.editSingleProductByCSVStatus = "loading";
    },
    [editSingleProductByCSV.fulfilled]: (state, action) => {
      state.editSingleProductByCSVStatus = "succeeded";
      state.notify = "Updated";
    },
    [editSingleProductByCSV.rejected]: (state, action) => {
      state.editSingleProductByCSVStatus = "failed";
      state.error = action?.error?.message
        ? JSON.parse(action?.error?.message)?.message
        : "Something went Wrong, Please Try again!";
    },
    [uploadProductCSV.pending]: (state) => {
      state.uploadProductCSVStatus = "loading";
    },
    [uploadProductCSV.fulfilled]: (state, action) => {
      state.uploadProductCSVStatus = "succeeded";
      state.notify = "Uploaded";
    },
    [uploadProductCSV.rejected]: (state, action) => {
      state.uploadProductCSVStatus = "failed";
      state.error = action?.error?.message
        ? JSON.parse(action?.error?.message)?.message
        : "Something went Wrong, Please Try again!";
    },
    [deleteProduct.pending]: (state) => {
      state.deleteProductStatus = "loading";
    },
    [deleteProduct.fulfilled]: (state, action) => {
      let findIndex = state.productData.findIndex(
        (obj) => obj._id === action.payload.deletedId
      );
      let newProductDataArray = [...state.productData];
      delete newProductDataArray[findIndex];
      state.productData = newProductDataArray.filter(function (el) {
        return el != null;
      });
      state.deleteProductStatus = "succeeded";
      state.notify = "Deleted";
    },
    [deleteProduct.rejected]: (state, action) => {
      state.deleteProductStatus = "failed";
      state.error = action?.error?.message
        ? JSON.parse(action?.error?.message)?.message
        : "Something went Wrong, Please Try again!";
    },
    [getProducts.pending]: (state) => {
      state.productDataStatus = "loading";
    },
    [getProducts.fulfilled]: (state, action) => {
      state.productDataStatus = "succeeded";
      state.productData = action.payload.data;
    },
    [getProducts.rejected]: (state, action) => {
      state.productDataStatus = "failed";
      state.error = action?.error?.message
        ? JSON.parse(action?.error?.message)?.message
        : "Something went Wrong, Please Try again!";
    },
    [getImportCSVData.pending]: (state) => {
      state.getImportDataStatus = "loading";
    },
    [getImportCSVData.fulfilled]: (state, action) => {
      state.getImportDataStatus = "succeeded";
      state.getImportData = action.payload.data;
    },
    [getImportCSVData.rejected]: (state, action) => {
      state.getImportDataStatus = "failed";
      state.error = action?.error?.message
        ? JSON.parse(action?.error?.message)?.message
        : "Something went Wrong, Please Try again!";
    },
  },
});

export const { clearNotify, clearErrorData } = productSlice.actions;

export default productSlice.reducer;
