import {
  Layout,
  Button,
  Form,
  Input,
  Row,
  Col,
  Card,
  Typography,
  Spin,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { footerText } from "../../Util/config";
import { sendResetPasswordLink } from "../../Store/Reducers/Auth/authSlice";

const { Header, Footer, Content } = Layout;
const { Title } = Typography;

const SendMail = () => {
  //#region hooks
  const dispatch = useDispatch();
  const [sendMail, setSendMail] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  //#endregion

  //#region selector
  const { sendMailStatus } = useSelector((state) => state.auth);
  //#endregion

  //#region function
  const onFinish = (values) => {
    dispatch(sendResetPasswordLink(values));
  };
  useEffect(() => {
    if (sendMailStatus === "loading") {
      setIsLoading(true);
    }
    if (sendMailStatus === "succeeded") {
      setIsLoading(false);
      setSendMail(true);
    }
    if (sendMailStatus === "failed") {
      setIsLoading(false);
    }
  }, [sendMailStatus]);

  const onFinishFailed = (errorInfo) => {};
  //#endregion
  if (isLoading)
    return (
      <Card style={{ textAlign: "center", margin: "20%", border: "none" }}>
        <Spin />
      </Card>
    );
  //#region render
  return sendMail ? (
    <Card style={{ textAlign: "center", margin: "20%" }}>
      <h3>Email Sent</h3>
      <p>We sent an email for reset password.</p>
    </Card>
  ) : (
    <Layout>
      <Header>
        {" "}
        <Row justify="center" align="middle">
          <Col>
            <Title level={2} style={{ color: "honeydew" }}>
              Milk Buddy Admin
            </Title>
          </Col>
        </Row>
      </Header>
      <Content>
        <Row justify="center" align="middle">
          <Col style={{ padding: "30px" }}>
            <Card title="Enter your email and we'll send you a  link to reset your password">
              <Form
                name="basic"
                labelCol={{
                  span: 8,
                }}
                wrapperCol={{
                  span: 16,
                }}
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Form.Item
                  label="email "
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please input your email!",
                    },
                    {
                      type: "email",
                      message: "Please enter valid email",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  wrapperCol={{
                    offset: 8,
                    span: 16,
                  }}
                >
                  <Button type="primary" htmlType="submit">
                    Send Reset Password Email
                  </Button>
                </Form.Item>
              </Form>
            </Card>{" "}
          </Col>
        </Row>
      </Content>
      <Footer>
        <Row justify="center" align="middle">
          <Col>
            <Title level={5} style={{ color: "firebrick" }}>
              {" "}
              {footerText} {new Date().getFullYear()}
            </Title>
          </Col>
        </Row>{" "}
      </Footer>
    </Layout>
  );

  //#endregion
};

export default SendMail;
