import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { Role } from "../../Util/commonUtility";

const PrivateRoute = () => {
  const localUser = JSON.parse(localStorage.getItem("user"));
  const auth = localUser ? true : false; // determine if authorized, from context or however you're doing it

  if (localUser?.data?.role === Role.Store) {
    return auth ? <Navigate to="/not-authorize" /> : <Navigate to="/login" />;
  }
  return auth ? <Outlet /> : <Navigate to="/login" />;
  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page
};
export default PrivateRoute;
