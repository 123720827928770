import {
  Layout,
  Button,
  Checkbox,
  Form,
  Input,
  Row,
  Col,
  Card,
  Typography,
  Space,
} from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { footerText } from "../../Util/config";
import { authUser } from "../../Store/Reducers/Auth/authSlice";
import { useNavigate } from "react-router-dom";
import { Role } from "../../Util/commonUtility";
import { Link } from "react-router-dom";
const { Header, Footer, Content } = Layout;
const { Title } = Typography;

const LoginPage = () => {
  //#region hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //#endregion

  //#region selector
  const localUser = localStorage.getItem("user");
  const autState = useSelector((state) => state.auth);
  //#endregion

  //#region useEffect
  useEffect(() => {
    if (localUser) {
      if (JSON.parse(localUser).data.role === Role.Store) {
        navigate("/store-dashboard");
      } else {
        navigate("/dashboard");
      }
    } else {
      navigate("/login");
    }
  }, [autState, navigate]);
  //#endregion

  //#region function
  const onFinish = (values) => {
    dispatch(authUser(values));
  };

  const onFinishFailed = (errorInfo) => {};
  //#endregion

  //#region render
  return (
    <Layout>
      <Header>
        {" "}
        <Row justify="center" align="middle">
          <Col>
            <Title level={2} style={{ color: "honeydew" }}>
              Milk Buddy Admin
            </Title>
          </Col>
        </Row>
      </Header>
      <Content>
        <Row justify="center" align="middle">
          <Col style={{ padding: "30px" }}>
            <Card>
              <Form
                name="basic"
                labelCol={{
                  span: 8,
                }}
                wrapperCol={{
                  span: 16,
                }}
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Form.Item
                  label="email "
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please input your email!",
                    },
                    {
                      type: "email",
                      message: "Please enter valid email",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                  style={{ textAlign: "right" }}
                >
                  <Input.Password />
                </Form.Item>

                <Form.Item
                  name="remember"
                  valuePropName="checked"
                  wrapperCol={{
                    offset: 8,
                    span: 16,
                  }}
                >
                  <Checkbox>Remember me</Checkbox>
                </Form.Item>

                <Form.Item
                  wrapperCol={{
                    offset: 8,
                    span: 16,
                  }}
                >
                  <Space>
                    <Button type="primary" htmlType="submit">
                      Submit
                    </Button>
                    <Link to="/sendLink" style={{ textAlilgn: "right" }}>
                      Reset password
                    </Link>
                  </Space>
                </Form.Item>
                <Form.Item
                  wrapperCol={{
                    offset: 8,
                    span: 16,
                  }}
                ></Form.Item>
              </Form>
            </Card>{" "}
          </Col>
        </Row>
      </Content>
      <Footer>
        <Row justify="center" align="middle">
          <Col>
            <Title level={5} style={{ color: "firebrick" }}>
              {" "}
              {footerText} {new Date().getFullYear()}
            </Title>
          </Col>
        </Row>{" "}
      </Footer>
    </Layout>
  );
  //#endregion
};

export default LoginPage;
