import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUsers, deleteUser } from "../../Store/Reducers/User/userSlice";
import { getStores } from "../../Store/Reducers/Store/storeSlice";
import { Card, Space, Breadcrumb, Button } from "antd";
import { HomeOutlined, UserOutlined } from "@ant-design/icons";
import "./user.scss";
import { useLocation } from "react-router-dom";
import UserList from "../../Components/User/userList";
import UserForm from "../../Components/User/userForm";
import StoreSelection from "../../Components/User/storeSelection";

const UserContainer = (props) => {
  let dispatch = useDispatch();
  const { state: locationState } = useLocation();
  const { userType, refresh } = locationState;

  const {
    userDataStatus,
    userData,
    saveUserStatus,
    updateUserStatus,
    updateStoreUserStatus,
  } = useSelector((state) => state.users);
  const { storeDataStatus, storeData } = useSelector((state) => state.stores);

  let [showUserForm, setShowUserForm] = useState({
    isOpen: false,
    editUserData: null,
    isEdit: false,
    isStoreAllocation: false,
  });

  useEffect(() => {
    if (storeDataStatus === "idle") {
      dispatch(getStores());
    }
  }, [dispatch, storeDataStatus]);
  useEffect(() => {
    if (userDataStatus === "idle") {
      dispatch(getUsers(userType));
    }
  }, [dispatch, userDataStatus]);

  useEffect(() => {
    dispatch(getUsers(userType));
    return () => {
      goBack();
    };
  }, [dispatch, userType]);

  useEffect(() => {
    if (
      saveUserStatus === "succeeded" ||
      updateUserStatus === "succeeded" ||
      updateStoreUserStatus === "succeeded"
    ) {
      dispatch(getUsers(userType));
      dispatch(getStores());
      setShowUserForm({
        isOpen: false,
        isEdit: false,
        editUserData: {},
        isStoreAllocation: false,
      });
    }
  }, [dispatch, saveUserStatus, updateUserStatus, updateStoreUserStatus]);

  const addUser = () => {
    setShowUserForm({
      isOpen: true,
      isEdit: false,
      editUserData: {},
      isStoreAllocation: false,
    });
  };

  const goBack = () => {
    setShowUserForm({
      isOpen: false,
      editUserData: {},
      isEdit: false,
      isStoreAllocation: false,
    });
  };
  const editUser = (data) => {
    data = { ...data, password: "" };
    setShowUserForm({
      isOpen: true,
      editUserData: data,
      isEdit: true,
      isStoreAllocation: false,
    });
  };
  const editStoreAllocation = (data) => {
    setShowUserForm({
      isOpen: true,
      editUserData: data,
      isEdit: false,
      isStoreAllocation: true,
    });
  };
  const deleteUserHandle = (id) => {
    dispatch(deleteUser(id));
  };
  const breadCrumbTitleHandle = (showUserForm) => {
    let text = "";
    switch (true) {
      case showUserForm?.isOpen && showUserForm?.isEdit:
        text = "Edit / " + showUserForm.editUserData.name;
        break;
      case showUserForm?.isOpen &&
        !showUserForm?.isEdit &&
        !showUserForm?.isStoreAllocation:
        text = "Add";
        break;
      case showUserForm?.isOpen && showUserForm?.isStoreAllocation:
        text =
          "Store Allocation / User Name : " + showUserForm.editUserData.name;
        break;
      default:
        text = "List";
        break;
    }
    return <Breadcrumb.Item>{text}</Breadcrumb.Item>;
  };

  return (
    <Card
      title={
        <Space
          direction="horizontal"
          style={{ width: "100%", justifyContent: "space-between" }}
        >
          <Breadcrumb>
            <Breadcrumb.Item>
              <HomeOutlined />
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <UserOutlined />
              <span>{userType === "Admin" ? "Admin" : "Store"} Users </span>
            </Breadcrumb.Item>
            {breadCrumbTitleHandle(showUserForm)}
          </Breadcrumb>
          {!showUserForm?.isEdit && !showUserForm?.isStoreAllocation && (
            <Button type="primary" onClick={addUser}>
              Add
            </Button>
          )}
        </Space>
      }
    >
      {showUserForm?.isOpen ? (
        <>
          {showUserForm.isStoreAllocation &&
          userType === "Store" &&
          storeData?.length > 0 ? (
            <StoreSelection
              showUserForm={showUserForm}
              goBack={goBack}
              storeData={storeData}
            />
          ) : (
            <UserForm showUserForm={showUserForm} goBack={goBack} />
          )}
        </>
      ) : (
        <UserList
          userData={userData}
          userType={userType}
          editStoreAllocation={editStoreAllocation}
          editUser={editUser}
          deleteUserHandle={deleteUserHandle}
        />
      )}
    </Card>
  );
};

export default UserContainer;
