const apiURL = process.env.REACT_APP_API_URL;

//#region admin Login
export const authUserRoute = function (params) {
  return apiURL + "admin/auth/login";
};
export const logoutUserRoute = function (params) {
  return apiURL + "admin/auth/logout";
};
export const resetPasswordLinkUserRoute= function(params){
  return apiURL +"admin/reset_passsword_mail";
}
export const verifyTokenUserRoutes=function(token){
  return apiURL+"admin/token_verify/"+token;
}
export const resetPasswordRoutes =function(params){
  return apiURL+"admin/reset_password";
}
//#region
//#region Admin
export const saveUserRoute = function (params) {
  return apiURL + "admin/create_admin";
};
export const updateUserRoute = function (userId) {
  return apiURL + "admin/update_admin/" + userId;
};
export const updateStoreUserRoute = function (ownerId) {
  return apiURL + "admin/add_update_store/" + ownerId;
};
export const getUsersRoute = function (role) {
  return apiURL + "admin/get_admins/" + role;
};
export const deleteUserRoute = function (userId) {
  return apiURL + "admin/delete_admin/" + userId;
};
export const getUserByIdRoute = function (userId) {
  return apiURL + "admin/users/" + userId;
};
//#region
//#region Products
export const saveProductRoute = function (params) {
  return apiURL + "admin/product";
};
export const updateProductRoute = function (productId) {
  return apiURL + "admin/product/" + productId;
};
export const getProductsRoute = function (params) {
  return apiURL + "admin/product";
};
export const deleteProductRoute = function (productId) {
  return apiURL + "admin/product/" + productId;
};
export const getProductByIdRoute = function (productId) {
  return apiURL + "admin/product/" + productId;
};
export const uploadProductCSVRoute = function () {
  return apiURL + "admin/product/import-csv";
};
export const editSingleProductByCSVRoute = function (productId) {

  return apiURL + "admin/product/import/resume-single-item/" + productId;
};
export const getImportCSVDataRoute = function () {
  return apiURL + "admin/product/import/get/data";
};
//#region
//#region stores
export const saveStoreRoute = function (params) {
  return apiURL + "admin/store";
};
export const updateStoreRoute = function (storeId) {
  return apiURL + "admin/store/" + storeId;
};
export const updateStoreProductRoute = function (storeId, productId) {
  return apiURL + "admin/store/" + storeId + "/" + productId;
};
export const getStoresRoute = function (type) {
  return apiURL + "admin/store/get/" + type;
};
export const deleteStoreRoute = function (storeId) {
  return apiURL + "admin/store/" + storeId;
};
export const getStoreByIdRoute = function (storeId) {
  return apiURL + "admin/store/" + storeId;
};
export const uploadStoreCSVRoute = function () {
  return apiURL + "admin/store/import-csv";
};
export const editSingleStoreByCSVRoute = function (storeId) {
  return apiURL + "admin/store/import/resume-single-item/" + storeId;
};
export const getImportStoreCSVDataRoute = function () {
  return apiURL + "admin/store/import/get/data";
};
//#region
