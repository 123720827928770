import React from "react";
import StoreContainer from "../../Containers/Store";
const Store = (props) => {
  return (
    <>
      <StoreContainer />
    </>
  );
};

export default Store;
