import React from "react";
import ProductUploadContainer from "../../Containers/Product/productUpload";
const ProductUpload = (props) => {
  return (
    <>
      <ProductUploadContainer />
    </>
  );
};

export default ProductUpload;
