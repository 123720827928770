import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import InputMask from "react-input-mask";
import {
  Card,
  Breadcrumb,
  Space,
  Button,
  Upload,
  message,
  Table,
  InputNumber,
  Badge,
  Input,
  Form,
  Typography,
  Modal,
  Checkbox,
  Image,
  Select,
  Row,
  Col,
} from "antd";
import "./storeUpload.scss";

import {
  HomeOutlined,
  UserOutlined,
  WarningOutlined,
  CheckCircleOutlined,
  UploadOutlined,
  SaveOutlined,
  CloseOutlined,
  InfoCircleOutlined,
  EditOutlined,
} from "@ant-design/icons";
import {
  uploadStoreCSV,
  getImportStoreCSVData,
  editSingleStoreByCSV,
} from "../../Store/Reducers/Store/storeSlice";
import { Link } from 'react-router-dom';
const { Option } = Select;
const { TextArea } = Input;
const StoreUploadContainer = (props) => {
  const dispatch = useDispatch();
  const { Paragraph, Text } = Typography;
  const [storeCSV, SetStoreCSV] = useState(null);
  const [form] = Form.useForm();
  let [storeEditForm] = Form.useForm();
  const [data, setData] = useState([]);
  const {
    uploadStoreCSVStatus,
    getImportData,
    getImportStoreDataStatus,
    editSingleStoreByCSVStatus,
  } = useSelector((state) => state.stores);
  const UploadCSV = () => {
    const formData = new FormData();
    formData.append("csv-file", storeCSV);
    dispatch(uploadStoreCSV(formData));
  };

  useEffect(() => {
    if (
      uploadStoreCSVStatus === "succeeded" ||
      editSingleStoreByCSVStatus === "succeeded"
    ) {
      dispatch(getImportStoreCSVData());
    }
  }, [uploadStoreCSVStatus, editSingleStoreByCSVStatus]);

  useEffect(() => {
    if (getImportStoreDataStatus === "idle") {
      dispatch(getImportStoreCSVData());
    }
  }, [getImportStoreDataStatus]);

  useEffect(() => {
    setData(getImportData);
  }, [getImportData]);

  const [editingKey, setEditingKey] = useState("");
  const isEditing = (record) => record._id === editingKey;
  const [modalEdit, setModalEdit] = useState(false);
  const [storeEditing, setStoreEditing] = useState("");
  const edit = (record) => {
    storeEditForm.setFieldsValue({
      name: "",
      description: "",
      email: "",
      contactName: "",
      contactNumber: "",
      type: "",
      fullAddress: "",
      phone: "",
      borough: "",
      street: "",
      city: "",
      postalCode: "",
      state: "",
      country: "",
      latitude: "",
      longitude: "",
      image: "",
      logo: "",
      status: "",
      googleLocationLink: "",

      ...record,
    });
    setEditingKey(record._id);
    setModalEdit(true);
  };

  const cancel = () => {
    setEditingKey("");
  };
  const resetEditing = () => {
    setModalEdit(false);
    setStoreEditing(null);
  };
  const onFinish = (data) => {
    let row = { ...data, _id: editingKey };
    save(row);
    resetEditing();
  };
  const displayErrorTag = (isValidField, text) => {
    return isValidField ? (
      <div className="Highlighted-Red">
        <WarningOutlined /> {text}
      </div>
    ) : (
      text
    );
  };
  const save = async (row) => {
    try {
      const newData = [...data];
      const index = newData.findIndex((item) => row._id === item._id);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setData(newData);
        setEditingKey("");
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey("");
      }
      dispatch(editSingleStoreByCSV(row));
    } catch (errInfo) {
     
    }
  };
  const tableColumns = [
    {
      title: "Status",
      dataIndex: "ErrStatus",
      fixed: "left",
      render: (text, record) =>
        record.ErrStatus === "error" ? (
          <>
            <WarningOutlined /> {text}
          </>
        ) : (
          text
        ),
      onCell: (record, index) => {
        return {
          style: {
            background: record.ErrStatus === "error" ? "#FF8484" : "",
          },
        };
      },
      filters: [
        {
          text: "Success",
          value: "success",
        },
        {
          text: "Error",
          value: "error",
        },
      ],
      onFilter: (value, record) => record.ErrStatus.indexOf(value) === 0,
    },
    {
      title: "name",
      dataIndex: "name",
      fixed: "left",
      width:"5%",
      ellipsis:"true",
      editable: true,
      render: (text, record) => displayErrorTag(record.errorJSON.name, text),
    },
    {
      title: "description",
      dataIndex: "description",
      editable: true,
      width:"5%",
      ellipsis:"true",
      render: (text, record) =>
        displayErrorTag(record.errorJSON.description, text),
    },
    {
      title: "email",
      dataIndex: "email",
      editable: true,
      ellipsis:"true",
      render: (text, record) => displayErrorTag(record.errorJSON.email, text),
    },
    {
      title: "type",
      dataIndex: "type",
      width:"5%",
      ellipsis:"true",
      editable: true,
      render: (text, record) => displayErrorTag(record.errorJSON.type, text),
    },
    {
      title: "image",
      dataIndex: "image",
      render: (name, record) =>
        record.errorJSON.image ? (
          <>
            <WarningOutlined />
            <Image src={name} width={20} height={20} />
          </>
        ) : (
          <Image src={name} width={20} height={20} />
        ),
      editable: true,
    },
    {
        title: "logo",
        dataIndex: "logo",
        width: "5%",
        render: (name, record) =>
          record.errorJSON.logo ? (
            <>
              <WarningOutlined />
              <Image src={name} width={20} height={20} />
            </>
          ) : (
            <Image src={name} width={20} height={20} />
          ),
        editable: true,
      },

    {
      title: "fullAddress",
      dataIndex: "fullAddress",
      editable: true,
      ellipsis: "true",
      render: (text, record) =>
        displayErrorTag(record.errorJSON.fullAddress, text),
    },
    {
      title: "phone",
      dataIndex: "phone",
      editable: true,
      width:"5%",
      ellipsis:"true",
      render: (text, record) => displayErrorTag(record.errorJSON.phone, text),
    },
    {
      title: "borough",
      dataIndex: "borough",
      editable: true,
      render: (text, record) => displayErrorTag(record.errorJSON.borough, text),
    },
    {
      title: "street",
      dataIndex: "street",
      editable: true,
      ellipsis: "true",
      render: (text, record) => displayErrorTag(record.errorJSON.street, text),
    },
    {
      title: "city",
      dataIndex: "city",
      editable: true,
      ellipsis: "true",
      render: (text, record) => displayErrorTag(record.errorJSON.city, text),
    },
    {
      title: "postalCode",
      dataIndex: "postalCode",
      editable: true,
      width:"5%",
      ellipsis:"true",
      render: (text, record) =>
        displayErrorTag(record.errorJSON.postalCode, text),
    },
    {
      title: "state",
      dataIndex: "state",
      editable: true,
      render: (text, record) => displayErrorTag(record.errorJSON.state, text),
    },

    {
      title: "country",
      dataIndex: "country",
      editable: true,
      render: (text, record) => displayErrorTag(record.errorJSON.country, text),
    },
    {
      title: "latitude",
      dataIndex: "latitude",
      editable: true,
      render: (text, record) =>
        displayErrorTag(record.errorJSON.latitude, text),
    },
    {
      title: "longitude",
      dataIndex: "longitude",
      editable: true,
      render: (text, record) =>
        displayErrorTag(record.errorJSON.longitude, text),
    },
    {
      title: "status",
      dataIndex: "status",
      editable: true,
      render: (text, record) => displayErrorTag(record.errorJSON.status, text),
    },
    {
      title: "googleLocationLink",
      dataIndex: "googleLocationLink",
      ellipsis: "true",
      editable: true,
      render: (text, record) =>
        displayErrorTag(record.errorJSON.googleLocationLink, text),
    },
    {
      title: "contactName",
      dataIndex: "contactName",
      width:"5%",
      ellipsis:"true",
      editable: true,
      render: (text, record) =>
        displayErrorTag(record.errorJSON.contactName, text),
    },
    {
      title: "contactNumber",
      dataIndex: "contactNumber",
      editable: true,
      width:"5%",
      ellipsis:"true",
      render: (text, record) =>
        displayErrorTag(record.errorJSON.contactNumber, text),
    },

    {
      title: "Action",
      fixed: "right",
      render: (_, record) => {
        const editable = isEditing(record);
        return record.ErrStatus === "error" ? (
          editable ? (
            <span>
              <Typography.Link
                onClick={() => save(record)}
                style={{
                  marginRight: 8,
                }}
              >
                <SaveOutlined style={{ fontSize: "15px", color: "#00008B" }} />
              </Typography.Link>
              <Typography.Link onClick={() => cancel()}>
                <CloseOutlined style={{ fontSize: "15px", color: "#FF0000" }} />
              </Typography.Link>
            </span>
          ) : (
            <Typography.Link
              disabled={editingKey !== ""}
              onClick={() => edit(record)}
            >
              <EditOutlined style={{ fontSize: "20px", color: "#00008B" }} />
            </Typography.Link>
          )
        ) : (
          <CheckCircleOutlined style={{ fontSize: "20px", color: "#90EE90" }} />
        );
      },
    },
  ];
  return (
    <Card
      title={
        <Space
          direction="horizontal"
          style={{ width: "100%", justifyContent: "space-between" }}
        >
          <Breadcrumb>
            <Breadcrumb.Item>
              <HomeOutlined />
            </Breadcrumb.Item>
            <Breadcrumb.Item>
            <Link to="/store">
              <UserOutlined />
              <span>Stores </span>
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Upload</Breadcrumb.Item>
          </Breadcrumb>
          {data?.length > 0 && (
            <Button
              type="primary"
              onClick={() => dispatch(getImportStoreCSVData())}
            >
              Refresh Status
            </Button>
          )}
        </Space>
      }
    >
      {data?.length > 0 ? (
        <Form form={form} component={false}>
          <Table
            bordered
            dataSource={data}
            rowKey={"_id"}
            columns={tableColumns}
            rowClassName="editable-row"
            pagination={{
              onChange: cancel,
              pageSize: 50,
            }}
            size="small"
            scroll={{
              x: 1540,
            }}
          />
          <Table />
          <Modal
            title="Edit Store"
            visible={modalEdit}
            onCancel={() => resetEditing()}
            footer={null}
            width={1000}
          >
            <Form
              name={storeEditForm}
              form={storeEditForm}
              initialValues={storeEditing}
              onFinish={onFinish}
              layout="vertical"
              autoComplete="off"
            >
              <Row gutter={[20, 20]}>
                <Col span={6}>
                  <Form.Item
                    name="name"
                    label="Store Name"
                    rules={[
                      {
                        required: true,
                        message: "Please input store name",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="email"
                    label="Store email"
                    rules={[
                      {
                        required: true,
                        message: "Please input store email",
                      },
                      {
                        type: "email",
                        message: "Please enter valid email",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="description"
                    label="Description"
                    rules={[
                      {
                        required: true,
                        message: "Please input description",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="type"
                    label="Store Type"
                    rules={[
                      {
                        required: true,
                        message: "Please input type",
                      },
                    ]}
                  >
                    <Select>
                      <Option value="Grocery store">Grocery store</Option>
                      <Option value="Convenience store">
                        Convenience store
                      </Option>
                      <Option value="Gas Station"> Gas Station </Option>
                      <Option value="Soft Drinks shop">Soft Drinks shop</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[20, 20]}>
                <Col span={6}>
                  <Form.Item
                    name="phone"
                    label="Phone"
                    rules={[
                      {
                        required: true,
                        message: "Please input phone",
                      },
                    ]}
                  >
                    <InputMask
                      mask="1 999-999-9999"
                      value={props.value}
                      onChange={props.onChange}
                      className="ant-input"
                    ></InputMask>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="status"
                    label="status"
                    rules={[
                      {
                        required: true,
                        message: "Please input status",
                      },
                    ]}
                  >
                    <Select placeholder="Please select status">
                      <Option value="active"> Active </Option>
                      <Option value="in-active"> In Active </Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="fullAddress"
                    label="Full Address"
                    hidden={false}
                    rules={[
                      {
                        required: true,
                        message: "Please input fullAddress",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="street"
                    label="Street"
                    rules={[
                      {
                        required: true,
                        message: "Please input street",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[20, 20]}>
                <Col span={6}>
                  <Form.Item
                    name="city"
                    label="City"
                    rules={[
                      {
                        required: true,
                        message: "Please input city",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="borough" label="Borough">
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="state"
                    label="State"
                    rules={[
                      {
                        required: true,
                        message: "Please input state",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="postalCode"
                    label="Postal Code"
                    rules={[
                      {
                        required: true,
                        message: "Please input postalCode",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[20, 20]} >
                <Col span={6}>
                  <Form.Item
                    name="country"
                    label="Country"
                    rules={[
                      {
                        required: true,
                        message: "Please input country",
                      },
                    ]}
                  >
                    <Select placeholder="Please select country">
                      <Option value="Canada"> Canada </Option>
                      <Option value="Usa"> U.S.A </Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="latitude"
                    label="Latitude"
                    rules={[
                      {
                        required: true,
                        message: "Please input latitude",
                      },
                    ]}
                  >
                    <Input type="number" placeholder="latitude" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="longitude"
                    label="Longitude"
                    rules={[
                      {
                        required: true,
                        message: "Please input longitude",
                      },
                    ]}
                  >
                    <Input type="number" placeholder="longitude" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="googleLocationLink"
                    label="Google Location Link"
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[20, 20]}>
                <Col span={6}>
                  <Form.Item name="image" label="Image">
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="logo" label="Logo">
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="contactNumber"
                    label="Contact Person Number"
                    rules={[
                      {
                        required: true,
                        message: "Please input contact number",
                      },
                    ]}
                  >
                    <InputMask
                      mask="1 999-999-9999"
                      value={props.value}
                      onChange={props.onChange}
                      className="ant-input"
                    ></InputMask>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="contactName"
                    label="Contact Person name"
                    rules={[
                      {
                        required: true,
                        message: "Please input contact name",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form>
          </Modal>
        </Form>
      ) : (
        <>
          <Upload
            accept=".csv"
            maxCount={1}
            showUploadList={true}
            onChange={(fileList) => {
              SetStoreCSV(fileList.file.originFileObj);
            }}
            beforeUpload={(file) => {}}
            customRequest={({ file, onSuccess, onError }) => {
              setTimeout(() => {
                onSuccess("ok");
              }, 0);
            }}
          >
            <Button icon={<UploadOutlined />}>
              Click to Upload your csv file
            </Button>
          </Upload>
          <Space style={{marginTop:'10px'}}>
          <Button
            type="primary"
          >
           <Link to="/store">Back</Link>
          </Button>
          <Button
            type="primary"
            onClick={() => {
              UploadCSV();
            }}
          >
            Submit
          </Button>
          
          </Space>
        </>
      )}
    </Card>
  );
};

export default StoreUploadContainer;
