import React from "react";
import { Space, Popconfirm,  Table } from "antd";
import { EditFilled, DeleteFilled, AppstoreOutlined } from "@ant-design/icons";
const UserList = (props) => {
  const { userData, userType, editStoreAllocation, editUser,deleteUserHandle } = props;
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Role",
      dataIndex: "role",
      render: (text) => {
        return text === "Admin" ? "Admin" : "Store Owner";
      },
    },
    {
      title: "Store",
      hidden: userType === "Admin" ? true : false,
      render: (_, record) => (
        <Space size="middle">
          <a
            onClick={() => {
              editStoreAllocation(record);
            }}
          >
            <AppstoreOutlined />
            Stores
          </a>
        </Space>
      ),
    },
    {
      title: "Action",
      render: (_, record) => (
        <Space size="middle">
          <a>
            <EditFilled
              onClick={() => {
                editUser(record);
              }}
            />
          </a>
          <Popconfirm
            title="Are you sure to delete this user?"
            onConfirm={() => {
              deleteUserHandle(record._id);
            }}
            onCancel={() => {}}
            okText="Yes"
            cancelText="No"
          >
            <a>
              <DeleteFilled />
            </a>
          </Popconfirm>
        </Space>
      ),
    },
  ];
  return (
    <Table
      columns={columns.filter((item) => !item.hidden)}
      dataSource={userData}
      rowKey={`_id`}
    />
  );
};
export default UserList;
