import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getUsersRoute,
  saveUserRoute,
  deleteUserRoute,
  updateUserRoute,updateStoreUserRoute
} from "./../../../Api/apiroutehelper";
import axiosConfig from "./../../../Api/axiosConfig";
export const saveUser = createAsyncThunk("saveUser", async (data, state) => {
  let routeToCall = saveUserRoute();
  const response = await axiosConfig.post(routeToCall, data);
  return response.data;
});
export const updateUser = createAsyncThunk(
  "updateUser",
  async (data, state) => {
    let routeToCall = updateUserRoute(data._id);
    const response = await axiosConfig.put(routeToCall, data);
    return response.data;
  }
);
export const updateStoreUser = createAsyncThunk(
  "updateStoreUser",
  async (data, state) => {
    let routeToCall = updateStoreUserRoute(data._id);
    const response = await axiosConfig.post(routeToCall, data);
    return response.data;
  }
);
export const getUsers = createAsyncThunk("getUsers", async (data, state) => {
  let routeToCall = getUsersRoute(data ? data : "Admin");
  const response = await axiosConfig.get(routeToCall, data);
  return response.data;
});
export const deleteUser = createAsyncThunk("deleteUser", async (id, state) => {
  let routeToCall = deleteUserRoute(id);
  const response = await axiosConfig.delete(routeToCall);
  let resData = {
    response: response.data,
    deletedId: id,
  };
  return resData;
});
export const userSlice = createSlice({
  name: "users",
  initialState: {
    userDataStatus: "idle",
    userData: [],
    saveUserStatus: "idle",
    deleteUserStatus: "idle",
    updateUserStatus: "idle",
    updateStoreUserStatus: "idle",
    error: null,
    notify: null,
  },
  reducers: {
    clearNotify(state, action) {
      state.notify = null;
    },
    clearErrorData(state, action) {
      state.error = null;
    },
  },
  extraReducers: {
    [saveUser.pending]: (state) => {
      state.saveUserStatus = "loading";
    },
    [saveUser.fulfilled]: (state, action) => {
  
      state.saveUserStatus = "succeeded";
      state.notify = "Saved";
    },
    [saveUser.rejected]: (state, action) => {
      state.saveUserStatus = "failed";
      state.error = action?.error?.message
        ? JSON.parse(action?.error?.message)?.message
        : "Something went Wrong, Please Try again!";
    },
    [updateUser.pending]: (state) => {
      state.updateUserStatus = "loading";
    },
    [updateUser.fulfilled]: (state, action) => {
      
      state.updateUserStatus = "succeeded";
      state.notify = "Updated";
    },
    [updateUser.rejected]: (state, action) => {
      state.updateUserStatus = "failed";
      state.error = action?.error?.message
        ? JSON.parse(action?.error?.message)?.message
        : "Something went Wrong, Please Try again!";
    },
    [updateStoreUser.pending]: (state) => {
      state.updateStoreUserStatus = "loading";
    },
    [updateStoreUser.fulfilled]: (state, action) => {
    
      state.updateStoreUserStatus = "succeeded";
      state.notify = "Updated";
    },
    [updateStoreUser.rejected]: (state, action) => {
      state.updateStoreUserStatus = "failed";
      state.error = action?.error?.message
        ? JSON.parse(action?.error?.message)?.message
        : "Something went Wrong, Please Try again!";
    },
    [deleteUser.pending]: (state) => {
      state.deleteUserStatus = "loading";
    },
    [deleteUser.fulfilled]: (state, action) => {
      let findIndex = state.userData.findIndex(
        (obj) => obj._id === action.payload.deletedId
      );
      let newUserDataArray = [...state.userData];
      delete newUserDataArray[findIndex];
      state.userData = newUserDataArray.filter(function (el) {
        return el != null;
      });
      state.deleteUserStatus = "succeeded";
      state.notify = "Deleted";
    },
    [deleteUser.rejected]: (state, action) => {
      state.deleteUserStatus = "failed";
    },
    [getUsers.pending]: (state) => {
      state.userDataStatus = "loading";
    },
    [getUsers.fulfilled]: (state, action) => {
      state.userDataStatus = "succeeded";
      state.userData = action.payload.data;
    },
    [getUsers.rejected]: (state, action) => {
      state.userDataStatus = "failed";
      state.error = action?.error?.message
        ? JSON.parse(action?.error?.message)?.message
        : "Something went Wrong, Please Try again!";
    },
  },
});

export const { clearNotify, clearErrorData } = userSlice.actions;

export default userSlice.reducer;
