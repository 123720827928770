import React, { useEffect, Fragment } from "react";
import { useDispatch } from "react-redux";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import Dashboard from "./../Screens/Dashboard";
import Notfound from "../Screens/ErrorPages/notFound";
import NotAuthorized from "../Screens/ErrorPages/notAuthorize";
import LoginPage from "../Screens/Authentication/login";
import { logoutUser } from "../Store/Reducers/Auth/authSlice";
import Store from "../Screens/Store";
import Product from "../Screens/Product";
import ProductUpload from "../Screens/Product/productUpload";
import StoreUpload from "../Screens/Store/storeUpload";
import PrivateRoute from "../Screens/PrivateRoute";
import MainLayout from "../Components/Layout/mainLayout";
import User from "../Screens/User";
import StoreDashboard from "./../Screens/StoreDashboard";
import SendMail from "../Screens/Authentication/sendMail";
import ResetPassword from "../Screens/Authentication/resetPassword";
const AppRoute = (props) => {
  //#region hooks
  const match = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  //#endregion

  //#region state

  //#endregion

  //#region selector
  const localUser = localStorage.getItem("user");
  //#endregion

  //#region useEffect
  useEffect(() => {
    if (match.pathname === "/logout") {
      dispatch(logoutUser(JSON.parse(localUser)));
      localStorage.removeItem("user");
      navigate("/login");
    }
  }, [match, navigate, dispatch]);

  //#endregion
  return (
    <>
      <Fragment>
        {(match.pathname === "/login" || match.pathname==="/resetPassword/"  || match.pathname==="/sendLink") ? (
          <Routes>
            {/* <Route path={"/logout"} element={<LoginPage />} exact /> */}
            <Route path={"/login"} element={<LoginPage />} exact />
            <Route  exact path={"/resetPassword"} element={<ResetPassword />} />
            <Route exact path={"/sendLink"} element={<SendMail />} />
          </Routes>
        ) : (
          <>
            <Routes>
              
            </Routes>  
            <MainLayout>
              <Routes>
                {/* <Route path={"/logout"} element={<LoginPage />} exact /> */}
                <Route exact path="/" element={<PrivateRoute />}>
                  <Route exact path="/" element={<Dashboard />} />
                  <Route path={"/dashboard"} exact element={<Dashboard />} />

                  <Route path={"/product"} exact element={<Product />} />
                  <Route
                    path={"/product/upload"}
                    exact
                    element={<ProductUpload />}
                  />
                  <Route path={"/admin-user"} exact element={<User />} />
                </Route>
                {/* <Route path={"*"} element={<Notfound />} /> */}
                <Route path={"/store"} exact element={<Store />} />
                <Route path={"/store/upload"} exact element={<StoreUpload />} />
                <Route path={"/not-authorize"} element={<NotAuthorized />} />
                <Route path={"/store-dashboard"} element={<StoreDashboard />} />
              </Routes>
            </MainLayout>
            
          </>
        )}
      </Fragment>
    </>
  );
};

export default AppRoute;
