import React from "react";
import AuthSliceToaster from "./AuthSliceToaster";
import UserSliceToaster from "./UserSliceToaster";
import ProductSliceToaster from "./ProductSliceToaster";
import StoreSliceToaster from "./StoreSliceToaster";

export default function ToasterContainer() {
  return (
    <>
      <AuthSliceToaster />
      <UserSliceToaster />
      <ProductSliceToaster />
      <StoreSliceToaster />
    </>
  );
}
