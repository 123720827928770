import React, { useEffect, useState, useRef, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import InputMask from "react-input-mask";
import {
  getStores,
  saveStore,
  updateStore,
  deleteStore,
  updateStoreProduct,
  getStoreById,
} from "../../Store/Reducers/Store/storeSlice";
import { getProducts } from "../../Store/Reducers/Product/productSlice";
import { getAuthUserDetails } from "../../Store/Reducers/Auth/authSlice";
import {
  Card,
  Space,
  Table,
  Input,
  Breadcrumb,
  Button,
  Form,
  Row,
  Col,
  Upload,
  Select,
  message,
  Image,
  Popconfirm,
  Divider,
} from "antd";
import {
  HomeOutlined,
  UserOutlined,
  EditFilled,
  DeleteFilled,
  UploadOutlined,
  SearchOutlined,
  PaperClipOutlined,
} from "@ant-design/icons";
import "./store.scss";
import {
  getAddressDetailsByPlaceData,
  randomNumber,
} from "../../Util/commonUtility";
import Highlighter from "react-highlight-words";
import StoreProductTransfer from "./StoreProductTransfer";
import AutoComplete from "react-google-autocomplete";
import { useNavigate } from "react-router-dom";

const { Option } = Select;
const { TextArea } = Input;
const formItemLayout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 14,
  },
  labelWrap: "true",
};
const StoreContainer = (props) => {
  let dispatch = useDispatch();
  const searchAddressInputRef = useRef(null);
  const navigate = useNavigate();
  let [storeForm] = Form.useForm();
  const {
    storeDataStatus,
    storeData,
    saveStoreStatus,
    deleteStoreStatus,
    updateStoreStatus,
    storeByIdData,
    storeByIdDataStatus,
  } = useSelector((state) => state.stores);
  const { productDataStatus, productData } = useSelector(
    (state) => state.products
  );
  const { authUserData } = useSelector((state) => state.auth);
  let [showStoreForm, setShowStoreForm] = useState({
    isOpen: false,
    editStoreData: null,
    isEdit: false,
    isViewStoreProduct: false,
  });

  let [imageFile, setImageFile] = useState(false);
  let [isAddressFieldsEditable, setIsAddressFieldsEditable] = useState(false);
  let [logoFile, setLogoFile] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const [targetKeys, setTargetKeys] = useState(null);
  const [storeProductTransferData, setStoreProductTransferData] =
    useState(null);
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);

  const onChange = (nextTargetKeys) => {
    setTargetKeys(nextTargetKeys);
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters, confirm, dataIndex) => {
    clearFilters();
    setSearchText("");
    handleSearch("", confirm, dataIndex);
  };
  const uploadStoreCSV = () => {
    // Navigate
    navigate("/store/upload");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              handleReset(clearFilters, confirm, dataIndex);
            }}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Sr Number",
      dataIndex: "store_id",
    },
    {
      title: "Store Name",
      dataIndex: "name",
      width: "12%",
      ...getColumnSearchProps("name"),
    },

    {
      title: "Type",
      dataIndex: "type",
    },
    {
      title: "Store Contact",
      dataIndex: "email",
      width: "15%",
      render: (text, value) => {
        return (
          <>
            {text} , {value.phone}
          </>
        );
      },
    },
    {
      title: "Contact Person",
      dataIndex: "contactName",
      width: "15%",
      render: (text, value) => {
        return (
          <>
            {text} , {value.contactNumber}
          </>
        );
      },
    },
    {
      title: "Full Address",
      dataIndex: "fullAddress",
      width: "20%",
      render: (text, value) => {
        return (
          <>
            {text} , {value.street}, {value.city}, {value.postalCode},{" "}
            {value.state}, {value.country}, {value.countryCode}
          </>
        );
      },
    },

    {
      title: "Logo",
      dataIndex: "logo",
      render: (text) => {
        return (
          text && (
            <Image
              width={100}
              src={`${process.env.REACT_APP_API_URL}${text}`}
            />
          )
        );
      },
    },
    {
      title: "Product",
      dataIndex: "product",
      render: (_, record) => (
        <a
          onClick={() => {
            viewStoreProduct(record);
          }}
        >
          <PaperClipOutlined /> Products
        </a>
      ),
    },
    {
      title: "Action",
      width: "8%",
      render: (_, record) => (
        <Space size="middle">
          <a>
            <EditFilled
              onClick={() => {
                editStore(record);
              }}
            />
          </a>
          <Divider type="vertical"></Divider>
          <Popconfirm
            title="Are you sure to delete this store?"
            onConfirm={() => {
              deleteStoreHandle(record._id);
            }}
            onCancel={() => {}}
            okText="Yes"
            cancelText="No"
          >
            <a>
              <DeleteFilled />
            </a>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    if (storeDataStatus === "idle") {
      dispatch(getStores());
    }
  }, [dispatch, storeDataStatus]);
  useEffect(() => {
    if (productDataStatus === "idle") {
      dispatch(getProducts());
    }
  }, [dispatch, productDataStatus]);
  useEffect(() => {
    if (storeByIdDataStatus === "succeeded") {
      const originTargetKeys = storeByIdData.products
        ? Array.from(storeByIdData.products).map((_, i) => {
            return _._id;
          })
        : [];
      setTargetKeys(originTargetKeys);
      let storeProductTransferData = Array.from(productData).map((_, i) => {
        if (originTargetKeys.includes(_._id)) {
          let findProduct = storeByIdData.products.find(
            (obj) => obj._id === _._id
          );
          return {
            key: _._id,
            brand: _.brand,
            description: _.description,
            price: findProduct.price,
            regularPrice: findProduct.regularPrice,
            pricePer100: findProduct.pricePer100,
          };
        } else {
          return {
            key: _._id,
            brand: _.brand,
            description: _.description,
            price: _.price,
            regularPrice: _.regularPrice,
            pricePer100: _.pricePer100,
          };
        }
      });
      setStoreProductTransferData(storeProductTransferData);
      setShowStoreForm({
        isOpen: true,
        editStoreData: storeByIdData,
        isEdit: false,
        isViewStoreProduct: true,
      });
    }
  }, [dispatch, storeByIdDataStatus]);

  useEffect(() => {
    if (saveStoreStatus === "succeeded" || updateStoreStatus === "succeeded") {
      dispatch(getStores());
      resetAllState();
      setShowStoreForm({
        isOpen: false,
        isEdit: false,
        editStoreData: {},
        isViewStoreProduct: false,
      });
    }
  }, [dispatch, saveStoreStatus, updateStoreStatus]);
  const addStore = () => {
    resetFormFields();
    setShowStoreForm({
      isOpen: true,
      isEdit: false,
      editStoreData: null,
      isViewStoreProduct: false,
    });
    setDisableSubmitButton(false);
  };
  const resetFormFields = () => {
    storeForm.setFieldsValue({
      logo: "",
      image: "",
      email: "",
      name: "",
      type: "",
      contactName: "",
      contactNumber: "",
      fullAddress: "",
      phone: "",
      borough: "",
      street: "",
      description: "",
      city: "",
      postalCode: "",
      state: "",
      country: "",
      countryCode: "",
      latitude: "",
      longitude: "",
      status: "",
      googleLocationLink: "",
      srNumber: "",
    });
  };
  const onFinish = (data) => {
    const formData = new FormData();
    formData.append("logo", logoFile);
    formData.append("image", imageFile);
    formData.append("email", data.email);
    formData.append("name", data.name);
    formData.append("type", data.type);
    formData.append("contactName", data.contactName);
    formData.append("contactNumber", data.contactNumber);
    formData.append("fullAddress", data.fullAddress);
    formData.append("phone", data.phone);
    formData.append("borough", data.borough);
    formData.append("street", data.street);
    formData.append("description", data.description);
    formData.append("city", data.city);
    formData.append("postalCode", data.postalCode);
    formData.append("state", data.state);
    formData.append("country", data.country);
    formData.append("countryCode", data.country === "canada" ? "CA" : "US");
    formData.append("latitude", data.latitude);
    formData.append("longitude", data.longitude);
    formData.append("status", data.status);
    formData.append("googleLocationLink", data.googleLocationLink);
    try {
      if (showStoreForm.isEdit || showStoreForm.isViewStoreProduct) {
        formData.append("_id", data._id);
        formData.append("srNumber", data.srNumber);
        if (showStoreForm.isViewStoreProduct) {
          const formData1 = new FormData();
          for (const name in showStoreForm.editStoreData) {
            if (name !== "products") {
              formData1.append(name, showStoreForm.editStoreData[name]);
            }
          }
          formData1.append("products", targetKeys);
          formData1.append("isStoreProductUpdated", true);
          dispatch(updateStore(formData1));
        } else {
          dispatch(updateStore(formData));
        }
      } else {
        formData.append("srNumber", randomNumber());
        dispatch(saveStore(formData));
      }
      setDisableSubmitButton(true);
      resetFormFields();
    } catch (error) {}
  };
  const onFinishFailed = () => {};
  const goBack = () => {
    resetAllState();
    setShowStoreForm({
      isOpen: false,
      editStoreData: {},
      isEdit: false,
      isViewStoreProduct: false,
    });
  };
  const editStore = (data) => {
    storeForm.setFieldsValue(data);
    setShowStoreForm({
      isOpen: true,
      editStoreData: data,
      isEdit: true,
      isViewStoreProduct: false,
    });
    setDisableSubmitButton(false);
  };
  const viewStoreProduct = (data) => {
    dispatch(getStoreById(data?._id));
    setDisableSubmitButton(false);
  };
  const deleteStoreHandle = (id) => {
    dispatch(deleteStore(id));
  };
  const changeStoreProductDetails = (data) => {
    dispatch(updateStoreProduct(data));
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const breadCrumbTitleHandle = (showStoreForm) => {
    let text = "";
    switch (true) {
      case showStoreForm?.isOpen && showStoreForm?.isEdit:
        text = "Edit / " + showStoreForm.editStoreData.name;
        break;
      case showStoreForm?.isOpen &&
        !showStoreForm?.isEdit &&
        !showStoreForm?.isViewStoreProduct:
        text = "Add";
        break;
      case showStoreForm?.isOpen && showStoreForm?.isViewStoreProduct:
        text = "Store Product/ " + showStoreForm.editStoreData.name;
        break;
      default:
        text = "List";
        break;
    }
    return <Breadcrumb.Item>{text}</Breadcrumb.Item>;
  };
  const onPlaceSelectedHandleChange = async (place) => {
    let data = await getAddressDetailsByPlaceData(place);
    storeForm.setFieldsValue(data);
  };
  const resetAllState = () => {
    setTargetKeys(null);
    setImageFile(null);
    setLogoFile(null);
  };
  const checkSize = (size) => {
    return size / 1024 / 1024 < 2;
  };
  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = checkSize(file.size);
    if (!isLt2M) {
      message.error("Image must smaller than 1MB!");
    }
    return isJpgOrPng && isLt2M;
  };
  return (
    <Card
      title={
        <Space
          direction="horizontal"
          style={{ width: "100%", justifyContent: "space-between" }}
        >
          <Breadcrumb>
            <Breadcrumb.Item>
              <HomeOutlined />
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <a href="#" onClick={goBack}>
                <UserOutlined />
                <span>Stores </span>
              </a>
            </Breadcrumb.Item>
            {breadCrumbTitleHandle(showStoreForm)}
          </Breadcrumb>
          {!showStoreForm?.isEdit &&
            !showStoreForm?.isViewStoreProduct &&
            authUserData?.data?.role === "Admin" && (
              <Space>
                <Button type="primary" onClick={uploadStoreCSV}>
                  Upload CSV
                </Button>
                <Button type="primary" onClick={addStore}>
                  Add
                </Button>
              </Space>
            )}
        </Space>
      }
    >
      {showStoreForm?.isOpen ? (
        <>
          <Form
            form={storeForm}
            name={storeForm}
            {...formItemLayout}
            initialValues={showStoreForm?.editStoreData}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            {showStoreForm?.isViewStoreProduct ? (
              <>
                <Row>
                  <Col span={24}>
                    <StoreProductTransfer
                      dataSource={storeProductTransferData}
                      targetKeys={targetKeys}
                      disabled={false}
                      showSearch={true}
                      onChange={onChange}
                      titles={[
                        <b>
                          <u>All Available Products</u>
                        </b>,
                        <b>
                          <u>All Products in Store</u>
                        </b>,
                      ]}
                      filterOption={(inputValue, item) => {
                        return (
                          item.brand
                            .toLowerCase()
                            .indexOf(inputValue.toLowerCase()) !== -1
                        );
                      }}
                      storeDetails={showStoreForm}
                      changeStoreProductDetails={changeStoreProductDetails}
                    />
                  </Col>
                  <Col span={12}> </Col>
                </Row>
              </>
            ) : (
              <>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      name="srNumber"
                      label="Serial Number"
                      hidden={true}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name="name"
                      label="Store Name"
                      rules={[
                        {
                          required: true,
                          message: "Please input store name",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="email"
                      label="Store email"
                      rules={[
                        {
                          required: true,
                          message: "Please input store email",
                        },
                        {
                          type: "email",
                          message: "Please enter valid email",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="description"
                      label="Description"
                      rules={[
                        {
                          required: true,
                          message: "Please input description",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="type"
                      label="Store Type"
                      rules={[
                        {
                          required: true,
                          message: "Please input type",
                        },
                      ]}
                    >
                      <Select placeholder="Type">
                        <Option value="groceryStore"> Grocery store </Option>
                        <Option value="convenienceStore">
                          {" "}
                          Convenience store{" "}
                        </Option>
                        <Option value="gasStation"> Gas Station </Option>
                        <Option value="softDrinksShop">
                          {" "}
                          Soft Drinks shop{" "}
                        </Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      name="phone"
                      label="Phone"
                      rules={[
                        {
                          required: true,
                          message: "Please input phone",
                        },
                      ]}
                    >
                      <InputMask
                        mask="1 999-999-9999"
                        value={props.value}
                        onChange={props.onChange}
                        className="ant-input"
                      ></InputMask>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="status"
                      label="status"
                      rules={[
                        {
                          required: true,
                          message: "Please input status",
                        },
                      ]}
                    >
                      <Select placeholder="Please select status">
                        <Option value="active"> Active </Option>
                        <Option value="in-active"> In Active </Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <Divider> Address Fields</Divider>
                <Row>
                  <Col span={12}>
                    <AutoComplete
                      ref={searchAddressInputRef}
                      readOnly={isAddressFieldsEditable}
                      disabled={isAddressFieldsEditable}
                      className="ant-input google-address-autocomplete"
                      apiKey={`${process.env.REACT_APP_Google_Place_API_URL}`}
                      options={{
                        types: ["address"],
                        componentRestrictions: { country: "ca" },
                      }}
                      onPlaceSelected={(place) =>
                        onPlaceSelectedHandleChange(place)
                      }
                    />
                  </Col>
                  <Col span={2}>Or</Col>
                  <Col span={10}>
                    <Button
                      type="primary"
                      onClick={() => {
                        searchAddressInputRef.current.value = "";
                        setIsAddressFieldsEditable(!isAddressFieldsEditable);
                        storeForm.setFieldsValue({
                          fullAddress: "",
                          street: "",
                          city: "",
                          borough: "",
                          state: "",
                          postalCode: "",
                          country: "",
                          latitude: "",
                          longitude: "",
                          googleLocationLink: "",
                        });
                      }}
                    >
                      {!isAddressFieldsEditable
                        ? "Manually Enter Address"
                        : "Search Address From Google"}
                    </Button>
                  </Col>
                </Row>

                <Row>
                  <Col span={12}>
                    <Form.Item
                      name="fullAddress"
                      label="Full Address"
                      hidden={false}
                      rules={[
                        {
                          required: true,
                          message: "Please input fullAddress",
                        },
                      ]}
                    >
                      <TextArea
                        readOnly={!isAddressFieldsEditable}
                        disabled={!isAddressFieldsEditable}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    {" "}
                    <Form.Item
                      name="street"
                      label="Street"
                      rules={[
                        {
                          required: true,
                          message: "Please input street",
                        },
                      ]}
                    >
                      <Input
                        readOnly={!isAddressFieldsEditable}
                        disabled={!isAddressFieldsEditable}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      name="city"
                      label="City"
                      rules={[
                        {
                          required: true,
                          message: "Please input city",
                        },
                      ]}
                    >
                      <Input
                        readOnly={!isAddressFieldsEditable}
                        disabled={!isAddressFieldsEditable}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="borough" label="Borough">
                      <Input
                        readOnly={!isAddressFieldsEditable}
                        disabled={!isAddressFieldsEditable}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      name="state"
                      label="State"
                      rules={[
                        {
                          required: true,
                          message: "Please input state",
                        },
                      ]}
                    >
                      <Input
                        readOnly={!isAddressFieldsEditable}
                        disabled={!isAddressFieldsEditable}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    {" "}
                    <Form.Item
                      name="postalCode"
                      label="Postal Code"
                      rules={[
                        {
                          required: true,
                          message: "Please input postalCode",
                        },
                      ]}
                    >
                      <Input
                        readOnly={!isAddressFieldsEditable}
                        disabled={!isAddressFieldsEditable}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      name="country"
                      label="Country"
                      rules={[
                        {
                          required: true,
                          message: "Please input country",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Please select country"
                        readOnly={!isAddressFieldsEditable}
                        disabled={!isAddressFieldsEditable}
                      >
                        <Option value="Canada"> Canada </Option>
                        <Option value="Usa"> U.S.A </Option>
                      </Select>
                    </Form.Item>{" "}
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="latitude - longitude"
                      style={{
                        marginBottom: 0,
                      }}
                    >
                      <Form.Item
                        name="latitude"
                        style={{
                          display: "inline-block",
                          width: "calc(50% - 12px)",
                        }}
                        rules={[
                          {
                            required: true,
                            message: "Please input latitude",
                          },
                        ]}
                      >
                        <Input
                          placeholder="latitude"
                          readOnly={!isAddressFieldsEditable}
                          disabled={!isAddressFieldsEditable}
                        />
                      </Form.Item>
                      <span
                        style={{
                          display: "inline-block",
                          width: "24px",
                          lineHeight: "32px",
                          textAlign: "center",
                        }}
                      >
                        -
                      </span>
                      <Form.Item
                        name="longitude"
                        style={{
                          display: "inline-block",
                          width: "calc(50% - 12px)",
                        }}
                        rules={[
                          {
                            required: true,
                            message: "Please input longitude",
                          },
                        ]}
                      >
                        <Input
                          placeholder="longitude"
                          readOnly={!isAddressFieldsEditable}
                          disabled={!isAddressFieldsEditable}
                        />
                      </Form.Item>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      name="googleLocationLink"
                      label="Google Location Link"
                    >
                      <TextArea
                        readOnly={!isAddressFieldsEditable}
                        disabled={!isAddressFieldsEditable}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Divider />
                <Row>
                  <Col span={12}>
                    <Form.Item
                      name="contactName"
                      label="Contact Person name"
                      rules={[
                        {
                          required: true,
                          message: "Please input contact name",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="contactNumber"
                      label="Contact Person Number"
                      rules={[
                        {
                          required: true,
                          message: "Please input contact number",
                        },
                      ]}
                    >
                      <InputMask
                        mask="1 999-999-9999"
                        value={props.value}
                        onChange={props.onChange}
                        className="ant-input"
                      ></InputMask>
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col span={12}>
                    <Form.Item
                      name="image"
                      label="Image"
                      extra="File formats: JPEG, PNG, GIF (max size 1MB)"
                    >
                      <Upload
                        name="image"
                        accept="image/png, image/jpeg"
                        listType="picture"
                        beforeUpload={beforeUpload}
                        maxCount={1}
                        customRequest={dummyRequest}
                        onChange={(fileList) => {
                          setImageFile(fileList.file.originFileObj);
                        }}
                      >
                        <Button>
                          <UploadOutlined /> Select image
                        </Button>
                      </Upload>
                      {showStoreForm?.isEdit &&
                        showStoreForm.editStoreData?.image && (
                          <Image
                            width={100}
                            src={`${process.env.REACT_APP_API_URL}${showStoreForm.editStoreData.image}`}
                          />
                        )}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="logo"
                      label="Logo"
                      extra="File formats: JPEG, PNG, GIF (max size 1MB)"
                    >
                      <Upload
                        name="logo"
                        listType="picture"
                        accept="image/png, image/jpeg"
                        maxCount={1}
                        beforeUpload={beforeUpload}
                        customRequest={dummyRequest}
                        onChange={(fileList) => {
                          setLogoFile(fileList.file.originFileObj);
                        }}
                      >
                        <Button>
                          <UploadOutlined /> Select logo
                        </Button>
                      </Upload>
                      {showStoreForm?.isEdit &&
                        showStoreForm.editStoreData?.logo && (
                          <Image
                            width={100}
                            src={`${process.env.REACT_APP_API_URL}${showStoreForm.editStoreData.logo}`}
                          />
                        )}
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}

            {!showStoreForm?.isEdit && !showStoreForm?.isViewStoreProduct ? (
              <></>
            ) : (
              <Form.Item name="_id" label="id" hidden={true}>
                <Input />
              </Form.Item>
            )}

            <Space
              direction="horizontal"
              style={{ width: "100%", justifyContent: "space-between" }}
            >
              <Button type="primary" onClick={goBack}>
                Back
              </Button>
              <Button
                disabled={disableSubmitButton}
                type="primary"
                htmlType="submit"
              >
                {showStoreForm.isEdit ? "Edit" : "Submit"}
              </Button>
            </Space>
          </Form>
        </>
      ) : (
        <>
          {storeData?.length > 0 ? (
            <Table columns={columns} dataSource={storeData} rowKey={`_id`} />
          ) : (
            <>No data found</>
          )}{" "}
        </>
      )}
    </Card>
  );
};

export default StoreContainer;
