import React, { Fragment, useState, useEffect, useRef } from "react";
import { Form, Card, Table, Space, Button, Input } from "antd";
import { updateStoreUser } from "../../Store/Reducers/User/userSlice";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
const StoreSelection = (props) => {
  const { showUserForm, goBack, storeData } = props;
  const dispatch = useDispatch();
  let [storeSelectionForm] = Form.useForm();
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const [select, setSelect] = useState({
    selectedRowKeys: [],
    loading: true,
  });
  const { selectedRowKeys, loading } = select;
  useEffect(() => {
    async function bindData() {
      await storeData?.forEach((record) => {
        if (record.ownerId === showUserForm?.editUserData?._id) {
          if (!selectedRowKeys.includes(record._id)) {
            selectedRowKeys.push(record._id);
          }
        }
      });
      setSelect({
        ...select,
        loading: false,
      });
    }
    if (storeData.length > 0 && showUserForm.isStoreAllocation) {
      bindData();
    }
  }, [showUserForm, storeData]);
  useEffect(() => {
    setSelect({
      loading: true,
      selectedRowKeys: [],
    });
  }, [showUserForm.isOpen]);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters, confirm, dataIndex) => {
    clearFilters();
    setSearchText("");
    handleSearch("", confirm, dataIndex);
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              handleReset(clearFilters, confirm, dataIndex);
            }}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const storeColumns = [
    {
      title: "Store Name",
      dataIndex: "name",
      width: "15%",
      ...getColumnSearchProps("name"),
    },

    {
      title: "Type",
      dataIndex: "type",
      width: "12%",
    },
    {
      title: "Store Contact",
      dataIndex: "email",
      width: "20%",
      render: (text, value) => {
        return (
          <>
            {text} , {value.phone}
          </>
        );
      },
    },
    {
      title: "Contact Person",
      dataIndex: "contactName",
      width: "20%",
      render: (text, value) => {
        return (
          <>
            {text} , {value.contactNumber}
          </>
        );
      },
    },
    {
      title: "Full Address",
      dataIndex: "fullAddress",
      width: "30%",
      render: (text, value) => {
        return (
          <>
            {text} , {value.street}, {value.city}, {value.postalCode},{" "}
            {value.state}, {value.country}, {value.countryCode}
          </>
        );
      },
    },
    
  ];
  const storeRowDisabledMethod = (record) => {
    if (record.ownerId) {
      if (record.ownerId === showUserForm?.editUserData?._id) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      storeSelectionForm.setFieldsValue({
        storeIds: selectedRowKeys,
      });
      setSelect({
        ...select,
        selectedRowKeys: selectedRowKeys,
      });
    },
    getCheckboxProps: (record) => ({
      disabled: storeRowDisabledMethod(record),
      name: record.name,
    }),
  };
  const onFinish = (data) => {
    data._id = showUserForm?.editUserData?._id;
    dispatch(updateStoreUser(data));
  };
  return (
    <Form
      name={storeSelectionForm}
      form={storeSelectionForm}
      initialValues={showUserForm?.editUserData}
      onFinish={onFinish}
    >
      <Fragment>
        {!loading ? (
          <Card title="Please select store">
            <Table
              rowSelection={rowSelection}
              columns={storeColumns}
              dataSource={storeData}
              rowKey="_id"
            />
          </Card>
        ) : (
          <>Loading</>
        )}

        <Form.Item
          name="storeIds"
          label="Select Store allocation"
          hidden={true}
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <Space
            direction="horizontal"
            style={{
              width: "100%",
              justifyContent: "",
            }}
          >
            <Button type="primary" onClick={goBack}>
              Back
            </Button>
            <Button type="primary" htmlType="submit">
              {showUserForm.isEdit ? "Edit" : "Submit"}
            </Button>
          </Space>
        </Form.Item>
      </Fragment>
    </Form>
  );
};
export default StoreSelection;
