import React from "react";
import ProductContainer from "../../Containers/Product";
const Product = (props) => {
  return (
    <>
      <ProductContainer />
    </>
  );
};

export default Product;
