import React from "react";
import { useSelector } from "react-redux";
import ToasterHandler from "../../Components/Common/ToastHandler";
import {
  clearErrorData,
  clearNotify,
} from "./../../Store/Reducers/Product/productSlice";
export default function ProductSliceToaster() {
  const { error, notify } = useSelector((state) => state.products);
  return (
    <>
      {error && <ToasterHandler error={error} cleanerAction={clearErrorData} />}
      {notify && <ToasterHandler notify={notify} cleanerAction={clearNotify} />}
    </>
  );
}
