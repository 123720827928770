export function generateUUID() {
  // Public Domain/MIT
  var d = new Date().getTime(); //Timestamp
  var d2 =
    (typeof performance !== "undefined" &&
      performance.now &&
      performance.now() * 1000) ||
    0; //Time in microseconds since page-load or 0 if unsupported
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = Math.random() * 16; //random number between 0 and 16
    if (d > 0) {
      //Use timestamp until depleted
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      //Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
}
export function randomNumber() {
  return Math.round(Math.random() * 10000000);
}
export const Role = {
  Admin: "Admin",
  Store: "Store",
};
export const isEmpty = (value) => {
  return (
    // null or undefined
    value == null ||
    // has length and it's zero
    (value.hasOwnProperty("length") && value.length === 0) ||
    // is an Object and has no keys
    (value.constructor === Object && Object.keys(value).length === 0)
  );
};
export const placeArray = [
  "postal_code",
  "country",
  "administrative_area_level_1",
  "locality",
  "sublocality",
  "street_number",
  "route",
];

export const getAddressDetailsByPlaceData = (place) => {
  const addressComponent = place.address_components;
  let addressDetails = {
    fullAddress: place.formatted_address,
    street: undefined,
    city: undefined,
    borough: undefined,
    state: undefined,
    postalCode: undefined,
    country: undefined,
    latitude: place.geometry.location.lat(),
    longitude: place.geometry.location.lng(),
    googleLocationLink: `https://www.google.com/maps/place/?q=place_id:${place.place_id}`,
  };
  placeArray.forEach(async (element) => {
    let obj = await addressComponent.find((obj) => obj.types.includes(element));
    switch (element) {
      case "postal_code":
        Object.assign(addressDetails, { postalCode: obj?.long_name });
        break;
      case "country":
        Object.assign(addressDetails, { country: obj?.long_name });
        break;
      case "route":
        Object.assign(addressDetails, { street: obj?.long_name });
        break;
      case "sublocality":
        Object.assign(addressDetails, { borough: obj?.long_name });
        break;
      case "locality":
        Object.assign(addressDetails, { city: obj?.long_name });
        break;
      case "administrative_area_level_1":
        Object.assign(addressDetails, { state: obj?.long_name });
        break;
      default:
        break;
    }
  });
  return addressDetails;
};
