import React from "react";
import UserContainer from "../../Containers/User";
const User = (props) => {
  return (
    <>
      <UserContainer />
    </>
  );
};

export default User;
