import React, { useState } from "react";
import {
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Table,
  Transfer,
  Typography,
} from "antd";
import difference from "lodash/difference";
const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === "number" ? <InputNumber /> : <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const StoreProductTransfer = ({
  dataSource,
  storeDetails,
  changeStoreProductDetails,
  ...restProps
}) => {
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState("");
  const [dataSource1, setDataSource1] = useState(dataSource);
  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      name: "",
      age: "",
      address: "",
      ...record,
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey("");
  };
  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...dataSource1];
      const index = newData.findIndex((item) => key === item.key);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setDataSource1(newData);
        setEditingKey("");
      } else {
        newData.push(row);
        setDataSource1(newData);
        setEditingKey("");
      }
      let data = row;
      data.productId = key;
      data.storeId = storeDetails?.editStoreData?._id;
      changeStoreProductDetails(data);
    } catch (errInfo) {
      
    }
  };

  const leftTableColumns = [
    {
      dataIndex: "brand",
      title: "Brand",
    },
    {
      dataIndex: "description",
      title: "Description",
    },
    {
      dataIndex: "price",
      title: "Price",
    },
    {
      dataIndex: "regularPrice",
      title: "Regular Price",
    },
    {
      dataIndex: "pricePer100",
      title: "Price Per 100",
    },
  ];
  const rightTableColumns = [
    {
      title: "Brand",
      dataIndex: "brand",
      editable: false,
    },
    {
      title: "Description",
      dataIndex: "description",
      editable: false,
    },
    {
      title: "Price",
      dataIndex: "price",
      editable: true,
    },
    {
      dataIndex: "regularPrice",
      title: "Regular Price",
      editable: true,
    },
    {
      dataIndex: "pricePer100",
      title: "Price Per 100",
      editable: true,
    },
    {
      title: "Operation",
      dataIndex: "operation",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.key)}
              style={{
                marginRight: 8,
              }}
            >
              Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <Typography.Link
            disabled={editingKey !== ""}
            onClick={() => edit(record)}
          >
            Edit
          </Typography.Link>
        );
      },
    },
  ];
  const mergedColumns = rightTableColumns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "pricePer100" ? "text" : "number",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  const leftStyle = {
    // backgroundColor: "red",
  };
  const rightStyle = {
    // backgroundColor: "blue",
  };
  return (
    <Transfer
      dataSource={dataSource1}
      {...restProps}
      listStyle={({ direction }) =>
        direction === "left" ? leftStyle : rightStyle
      }
    >
      {({
        direction,
        filteredItems,
        onItemSelectAll,
        onItemSelect,
        selectedKeys: listSelectedKeys,
        // disabled: listDisabled,
      }) => {
        const columns = direction === "left" ? leftTableColumns : mergedColumns;

        const size = "small";
        // const editableCell =
        //   direction === "left" ? "leftColumns" : EditableCell;
        const rowSelection = {
          getCheckboxProps: (item) => ({
            // disabled: listDisabled || item.disabled,
          }),

          onSelectAll(selected, selectedRows) {
            const treeSelectedKeys = selectedRows
              // .filter((item) => !item.disabled)
              .map(({ key }) => key);
            const diffKeys = selected
              ? difference(treeSelectedKeys, listSelectedKeys)
              : difference(listSelectedKeys, treeSelectedKeys);
            onItemSelectAll(diffKeys, selected);
          },

          onSelect({ key }, selected) {
            onItemSelect(key, selected);
          },
          selectedRowKeys: listSelectedKeys,
        };
        return (
          <Form form={form} component={false}>
            <Table
              components={{
                body: {
                  cell: EditableCell,
                },
              }}
              bordered
              size={size}
              rowSelection={rowSelection}
              dataSource={filteredItems}
              columns={columns}
              rowClassName="editable-row"
              pagination={{
                onChange: cancel,
              }}
            />
          </Form>
        );
      }}
    </Transfer>
  );
};

export default StoreProductTransfer;
