import React, { useState } from "react";
import { logo } from "../../Resource/images";
import "./mainLayout.scss";
import { Breadcrumb, Button, Layout, Menu, Card } from "antd";
import {
  AppstoreOutlined,
  MailOutlined,
  SettingOutlined,
  LogoutOutlined,
  UserOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

import { footerText } from "../../Util/config";
import { Role } from "../../Util/commonUtility";
const { Header, Content, Footer, Sider } = Layout;
const adminNavItemsSidebar = [
  {
    label: "Dashboard",
    key: "Dashboard",
    icon: <AppstoreOutlined />,
  },
  {
    label: "Stores",
    key: "Store",
    icon: <MailOutlined />,
  },
  {
    label: "Products",
    key: "Product",
    icon: <MailOutlined />,
  },
  {
    label: "Admin Users",
    key: "AdminUser",
    icon: <UserOutlined />,
  },
  {
    label: "Store Users",
    key: "StoreUser",
    icon: <UsergroupAddOutlined />,
  },
  {
    label: "Logout",
    key: "Logout",
    icon: <LogoutOutlined />,
  },
  
];
const storeAdminNavItemsSidebar = [
  {
    label: "Dashboard",
    key: "Store-Dashboard",
    icon: <AppstoreOutlined />,

  },
  {
    label: "Stores",
    key: "Store",
    icon: <MailOutlined />,
  },
  {
    label: "Logout",
    key: "Logout",
    icon: <LogoutOutlined />,
  },
  
];
const navItemHeaders = [
  {
    label: "Setting",
    key: "Setting",
    icon: <SettingOutlined />,
    children: [
      {
        type: "group",
        children: [
          {
            label: "User Profile",
            key: "UserProfile",
            icon: <UserOutlined />,
          },
          {
            label: "Logout",
            key: "Logout",
            icon: <LogoutOutlined />,
          },
        ],
      },
    ],
  },
];
const MainLayout = (props) => {
  const navigate = useNavigate();
  const [selectedMenuItem, setSelectedMenuItem] = useState("dashboard");
  const localUser = JSON.parse(localStorage.getItem("user"));
  const onMenuItemClick = (e) => {
    setSelectedMenuItem(e.key);
    switch (e.key) {
      case "Store":
        navigate("/store");
        break;
      case "Product":
        navigate("/product");
        break;
      case "Dashboard":
        navigate("/");
        break;
      case "Store-Dashboard":
        navigate("/store-dashboard");
        break;
      case "Logout":
        navigate("/logout");
        break;
      case "UserProfile":
        navigate("/user-profile");
        break;
      case "AdminUser":
        navigate("/admin-user", { state: { userType: "Admin" } });
        break;
      case "StoreUser":
        navigate("/admin-user", { state: { userType: "Store" } });
        break;
      case "setting:2":
  
        break;
      case "setting:3":
        
        break;
      case "setting:4":
        
        break;

      default:
        break;
    }
  };
  return (
    <Layout className="layout">
      <Header>
        <img
          className="logo"
          src={logo}
          alt="logo"
          onClick={() => {
            navigate("/");
          }}
        />
        {/* <Menu
          theme="light"
          mode="horizontal"
          defaultSelectedKeys={["1"]}
          onClick={onMenuItemClick}
          selectedKeys={[selectedMenuItem]}
          items={navItemHeaders}
          style={{
            height: "100%",
            borderRight: 0, 
          }}
        /> */}
      </Header>
      <Layout>
        <Sider width={300} className="site-layout-background">
          <Menu
            theme="light"
            mode="inline"
            defaultSelectedKeys={["1"]}
            onClick={onMenuItemClick}
            selectedKeys={[selectedMenuItem]}
            items={
              localUser?.data?.role === Role.Admin
                ? adminNavItemsSidebar
                : storeAdminNavItemsSidebar
            }
            style={{
              height: "100%",
              borderRight: 0,
            }}
          />
        </Sider>
        <Layout>
          <Content>
            <Card>{props.children}</Card>
          </Content>
        </Layout>
      </Layout>
      <Footer className="footer">
        {footerText} {new Date().getFullYear()}
      </Footer>
    </Layout>
  );
};

export default MainLayout;
