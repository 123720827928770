import { combineReducers } from "redux";

import users from "./User/userSlice";
import products from "./Product/productSlice";
import stores from "./Store/storeSlice";
import auth from "./Auth/authSlice";

export default combineReducers({
  users,
  stores,
  products,
  auth,
});
