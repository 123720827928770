import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { message } from "antd";
const ToasterHandler = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (props.error) {
      if (props.error) {
        message.error(props.error);
      }
    } else if (props.notify) {
      message.success(props.notify);
    } else if (props.warning) {
      message.warning(props.warning);
    }
    if (props.cleanerAction) {
      dispatch(props.cleanerAction());
    }
  }, [message, props, dispatch]);
  return <></>;
};

export default ToasterHandler;
