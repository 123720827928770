import React from "react";
import StoreDashboardContainer from "../../Containers/StoreDashboard";
const StoreDashboard = (props) => {
  return (
    <>
      <StoreDashboardContainer />
    </>
  );
};

export default StoreDashboard;
