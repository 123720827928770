import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getStoresRoute,
  saveStoreRoute,
  deleteStoreRoute,
  updateStoreRoute,
  updateStoreProductRoute,getStoreByIdRoute,
  uploadStoreCSVRoute,
  getImportStoreCSVDataRoute,
  editSingleStoreByCSVRoute,
} from "./../../../Api/apiroutehelper";
import axiosConfig from "./../../../Api/axiosConfig";
export const saveStore = createAsyncThunk("saveStore", async (data, state) => {
  let routeToCall = saveStoreRoute();
  
  const response = await axiosConfig.post(routeToCall, data, {
    headers: {
      "content-type": "multipart/form-data",
    },
  });

  return response.data;
});

export const uploadStoreCSV = createAsyncThunk(
  "uploadStoreCSV",
  async (data, state) => {
    let routeToCall = uploadStoreCSVRoute();
    const response = await axiosConfig.post(routeToCall, data, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
    return response.data;
  }
);
export const getImportStoreCSVData = createAsyncThunk(
  "getImportStoreCSVData",
  async (data, state) => {
    let routeToCall = getImportStoreCSVDataRoute();
    const response = await axiosConfig.get(routeToCall, data);
    return response.data;
  }
);
export const editSingleStoreByCSV = createAsyncThunk(
  "editSingleStoreByCSV",
  async (data, state) => {
    let routeToCall = editSingleStoreByCSVRoute(data._id);
    const response = await axiosConfig.put(routeToCall, data);
    return response.data;
  }
);
export const updateStore = createAsyncThunk(
  "updateStore",
  async (data, state) => {
    let routeToCall = updateStoreRoute(data.get("_id"));
    data.delete("_id");
    const response = await axiosConfig.put(routeToCall, data, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
    return response.data;
  }
);
export const updateStoreProduct = createAsyncThunk(
  "updateStoreProduct",
  async (data, state) => {
    let routeToCall = updateStoreProductRoute(data.storeId, data.productId);
    const response = await axiosConfig.put(routeToCall, data);
    return response.data;
  }
);
export const getStores = createAsyncThunk("getStores", async (data, state) => {
  const localUser = JSON.parse(localStorage.getItem("user"));
  const type = localUser?.data?.role === "Admin" ? "admin" : "owner";
  let routeToCall = getStoresRoute(type);
  const response = await axiosConfig.get(routeToCall, data);
  return response.data;
});
export const getStoreById = createAsyncThunk(
  "getStoreById",
  async (id, state) => {
    let routeToCall = getStoreByIdRoute(id);
    const response = await axiosConfig.get(routeToCall);
    return response.data;
  }
);
export const deleteStore = createAsyncThunk(
  "deleteStore",
  async (id, state) => {
    let routeToCall = deleteStoreRoute(id);
    const response = await axiosConfig.delete(routeToCall);
    let resData = {
      response: response.data,
      deletedId: id,
    };
    return resData;
  }
);
export const storeSlice = createSlice({
  name: "stores",
  initialState: {
    storeDataStatus: "idle",
    storeData: {},
    saveStoreStatus: "idle",
    deleteStoreStatus: "idle",
    updateStoreStatus: "idle",
    storeByIdDataStatus: "idle",
    uploadStoreCSVStatus: "idle",
    getImportStoreDataStatus: "idle",
    editSingleStoreByCSVStatus: "idle",
    getImportData: {},
    storeByIdData: {},
    error: null,
    notify: null,
  },
  reducers: {
    clearNotify(state, action) {
      state.notify = null;
    },
    clearErrorData(state, action) {
      state.error = null;
    },
  },
  extraReducers: {
    [saveStore.pending]: (state) => {
      state.saveStoreStatus = "loading";
    },
    [saveStore.fulfilled]: (state, action) => {
      
      state.saveStoreStatus = "succeeded";
      state.notify = "Saved";
    },
    [saveStore.rejected]: (state, action) => {
      state.saveStoreStatus = "failed";
      state.error = action?.error?.message
        ? JSON.parse(action?.error?.message)?.message
        : "Something went Wrong, Please Try again!";
    },
    [updateStore.pending]: (state) => {
      state.updateStoreStatus = "loading";
    },
    [updateStore.fulfilled]: (state, action) => {
      state.updateStoreStatus = "succeeded";
      state.notify = "Updated";
    },
    [updateStore.rejected]: (state, action) => {
      state.updateStoreStatus = "failed";
      state.error = action?.error?.message
        ? JSON.parse(action?.error?.message)?.message
        : "Something went Wrong, Please Try again!";
    },
    [deleteStore.pending]: (state) => {
      state.deleteStoreStatus = "loading";
    },
    [deleteStore.fulfilled]: (state, action) => {
      let findIndex = state.storeData.findIndex(
        (obj) => obj._id === action.payload.deletedId
      );
      let newStoreDataArray = [...state.storeData];
      delete newStoreDataArray[findIndex];
      state.storeData = newStoreDataArray.filter(function (el) {
        return el != null;
      });
      state.deleteStoreStatus = "succeeded";
      state.notify = "Deleted";
    },
    [deleteStore.rejected]: (state, action) => {
      state.deleteStoreStatus = "failed";
      state.error = action?.error?.message
        ? JSON.parse(action?.error?.message)?.message
        : "Something went Wrong, Please Try again!";
    },
    [getStores.pending]: (state) => {
      state.storeDataStatus = "loading";
    },
    [getStores.fulfilled]: (state, action) => {
      state.storeDataStatus = "succeeded";
      state.storeData = action.payload.data;
    },
    [getStores.rejected]: (state, action) => {
      state.storeDataStatus = "failed";
      state.error = action?.error?.message
        ? JSON.parse(action?.error?.message)?.message
        : "Something went Wrong, Please Try again!";
    },
    [getStoreById.pending]: (state) => {
      state.storeByIdDataStatus = "loading";
    },
    [getStoreById.fulfilled]: (state, action) => {
      state.storeByIdDataStatus = "succeeded";
      state.storeByIdData = action.payload.data;
    },
    [getStoreById.rejected]: (state, action) => {
      state.storeByIdDataStatus = "failed";
      state.error = action?.error?.message
        ? JSON.parse(action?.error?.message)?.message
        : "Something went Wrong, Please Try again!";
    },
    [editSingleStoreByCSV.pending]: (state) => {
      state.editSingleStoreByCSVStatus = "loading";
    },
    [editSingleStoreByCSV.fulfilled]: (state, action) => {
      state.editSingleStoreByCSVStatus = "succeeded";
      state.notify = "Updated";
    },
    [editSingleStoreByCSV.rejected]: (state, action) => {
      state.editSingleStoreByCSVStatus = "failed";
      state.error = action?.error?.message
        ? JSON.parse(action?.error?.message)?.message
        : "Something went Wrong, Please Try again!";
    },
    [uploadStoreCSV.pending]: (state) => {
      state.uploadStoreCSVStatus = "loading";
    },
    [uploadStoreCSV.fulfilled]: (state, action) => {
      state.uploadStoreCSVStatus = "succeeded";
      state.notify = "Uploaded";
    },
    [uploadStoreCSV.rejected]: (state, action) => {
      state.uploadStoreCSVStatus = "failed";
      state.error = action?.error?.message
        ? JSON.parse(action?.error?.message)?.message
        : "Something went Wrong, Please Try again!";
    },
    [getImportStoreCSVData.pending]: (state) => {
      state.getImportStoreDataStatus = "loading";
    },
    [getImportStoreCSVData.fulfilled]: (state, action) => {
      state.getImportStoreDataStatus = "succeeded";
      state.getImportData = action.payload.data;
    },
    [getImportStoreCSVData.rejected]: (state, action) => {
      state.getImportStoreDataStatus = "failed";
      state.error = action?.error?.message
        ? JSON.parse(action?.error?.message)?.message
        : "Something went Wrong, Please Try again!";
    },
  },
});

export const { clearNotify, clearErrorData } = storeSlice.actions;

export default storeSlice.reducer;
