import {
  Layout,
  Button,
  Checkbox,
  Form,
  Input,
  Row,
  Col,
  Card,
  Typography,
} from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { footerText } from "../../Util/config";
import {
  verifyToken,
  resetPassword,
} from "../../Store/Reducers/Auth/authSlice";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useState } from "react";
import { Link } from "react-router-dom";
const { Header, Footer, Content } = Layout;
const { Title } = Typography;
const { Meta } = Card;
const ResetPassword = () => {
  //#region hooks

  const [searchParams, setSearchParams] = useSearchParams();
  let token = searchParams.get("token");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isValidToken, setIsValidToken] = useState(false);
  //#endregion

  //#region selector
  const { verifyTokenStatus, resetPasswordStatus } = useSelector(
    (state) => state.auth
  );
  //#endregion

  //#region function
  const onFinish = (values) => {
    let data = { resetPasswordToken: token, password: values.password };
    dispatch(resetPassword(data));
  };
  useEffect(() => {
    if (verifyTokenStatus === "idle") {
      dispatch(verifyToken(token));
    }
    if (verifyTokenStatus === "succeeded") {
      setIsValidToken(true);
    }
    if (verifyTokenStatus === "failed") {
      setIsValidToken(false);
    }
  }, [verifyTokenStatus]);
  useEffect(() => {
    if (resetPasswordStatus === "succeeded") {
      navigate("/login");
    }
  }, [resetPasswordStatus]);

  const onFinishFailed = (errorInfo) => {};
  //#endregion

  //#region render
  return isValidToken ? (
    <Layout>
      <Header>
        {" "}
        <Row justify="center" align="middle">
          <Col>
            <Title level={2} style={{ color: "honeydew" }}>
              Milk Buddy Admin
            </Title>
          </Col>
        </Row>
      </Header>
      <Content>
        <Row justify="center" align="middle">
          <Col style={{ padding: "30px" }}>
            <Card>
              <Meta
                title="Create New Password"
                description="We'll ask for this password whenever you Sign-in"
              />
              <Form
                style={{ marginTop: "20px" }}
                name="basic"
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                layout="vertical"
              >
                <Form.Item
                  name="password"
                  label="New Password"
                  rules={[
                    {
                      required: true,
                      message: "Please input valid password!",
                      pattern: new RegExp(
                        /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/g
                      ),
                    },
                  ]}
                  hasFeedback
                >
                  <Input.Password />
                </Form.Item>

                <Form.Item
                  name="confirm"
                  label="Confirm Password"
                  dependencies={["password"]}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: "Please confirm your password!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            "The two passwords that you entered do not match!"
                          )
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password />
                </Form.Item>
                <Form.Item>
                  <Button width="100%" type="primary" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              </Form>
              <div>
                Password Tips
                <ul style={{ paddingLeft: "20px" }}>
                  <li>Password length atleast 8 character.</li>
                  <li>Atleast one number.</li>
                  <li>Atleast one capital letter.</li>
                  <li>Atleast one small letter.</li>
                </ul>
              </div>
            </Card>{" "}
          </Col>
        </Row>
      </Content>
      <Footer>
        <Row justify="center" align="middle">
          <Col>
            <Title level={5} style={{ color: "firebrick" }}>
              {" "}
              {footerText} {new Date().getFullYear()}
            </Title>
          </Col>
        </Row>{" "}
      </Footer>
    </Layout>
  ) : (
    <Card style={{ textAlign: "center", margin: "20%" }}>
      Invalid Link..Please send link again.
      <Link to="/sendLink" style={{ textAlilgn: "right" }}>
        Reset password
      </Link>
    </Card>
  );

  //#endregion
};

export default ResetPassword;
