import React, { Fragment } from "react";
import { Space, Input, Button, Form, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  saveUser,
  updateUser,
  updateStoreUser,
} from "../../Store/Reducers/User/userSlice";
const { Option } = Select;
const UserForm = (props) => {
  const { showUserForm, goBack } = props;
  const dispatch = useDispatch();
  let [userForm] = Form.useForm();
  const onFinish = (data) => {
    if (showUserForm.isEdit) {
      dispatch(updateUser(data));
    } else if (showUserForm.isStoreAllocation) {
      data._id = showUserForm?.editUserData?._id;
      dispatch(updateStoreUser(data));
    } else {
      dispatch(saveUser(data));
    }
  };
  const onFinishFailed = () => {
    // setShowUserForm({ isOpen: true, editUserData: {} });
  };
  return (
    <Form
      name={userForm}
      form={userForm}
      labelCol={{
        span: 3,
      }}
      wrapperCol={{
        span: 8,
      }}
      initialValues={showUserForm?.editUserData}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Fragment>
        <Form.Item
          name="email"
          label="E-mail"
          rules={[
            {
              type: "email",
              message: "The input is not valid E-mail!",
            },
            {
              required: true,
              message: "Please input your E-mail!",
            },
          ]}
        >
          <Input disabled={showUserForm?.isEdit} />
        </Form.Item>
        {!showUserForm?.isEdit ||
        showUserForm?.editUserData?.role === "Store" ? (
          <>
            <Form.Item
              name="password"
              label="Password"
              rules={[
                {
                  required: true,
                  message: "Please input valid password!",
                  pattern:new RegExp(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/g),
                },
              ]}
              hasFeedback
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              name="confirm"
              label="Confirm Password"
              dependencies={["password"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }

                    return Promise.reject(
                      new Error(
                        "The two passwords that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
            {showUserForm?.editUserData?.role === "Store" && (
              <Form.Item name="_id" label="id" hidden={true}>
                <Input />
              </Form.Item>
            )}
          </>
        ) : (
          <Form.Item name="_id" label="id" hidden={true}>
            <Input />
          </Form.Item>
        )}
        <Form.Item
          name="name"
          label="Name"
          tooltip="What do you want others to call you?"
          rules={[
            {
              required: true,
              message: "Please input your name!",
              whitespace: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="role"
          label="Role of User"
          rules={[
            {
              required: true,
              message: "Please select role",
            },
          ]}
        >
          <Select placeholder="Type">
            <Option value="Admin"> Admin </Option>
            <Option value="Store"> Store owner </Option>
          </Select>
        </Form.Item>
      </Fragment>
      <Form.Item
        wrapperCol={{
          offset: 0,
          span: 11,
        }}
      >
        <Space
          direction="horizontal"
          style={{
            width: "100%",
            justifyContent: showUserForm.isStoreAllocation
              ? ""
              : "space-between",
          }}
        >
          <Button type="primary" onClick={goBack}>
            Back
          </Button>
          <Button type="primary" htmlType="submit">
            {showUserForm.isEdit ? "Edit" : "Submit"}
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};
export default UserForm;
