import React from "react";
import DashboardContainer from "../../Containers/Dashboard";
const Dashboard = (props) => {
  return (
    <>
      <DashboardContainer />
    </>
  );
};

export default Dashboard;
