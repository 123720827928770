import React from "react";
import StoreUploadContainer from "../../Containers/Store/storeUpload";
const StoreUpload = (props) => {
  return (
    <>
      <StoreUploadContainer />
    </>
  );
};

export default StoreUpload;
