import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./App.css";
import "./Scss/application.scss";
import AppRoute from "./Routes/AppRoute";
import ToasterContainer from "./Containers/ToastContainer";
import { getAuthUserDetails } from "./Store/Reducers/Auth/authSlice";
import { isEmpty } from "./Util/commonUtility";
function App() {
  const dispatch = useDispatch();
  const { authUserData } = useSelector((state) => state.auth);
  useEffect(() => {
    if (isEmpty(authUserData)) {
      dispatch(getAuthUserDetails());
    }
  }, [authUserData]);

  return (
    <>
      <AppRoute />
      <ToasterContainer />
    </>
  );
}

export default App;
