import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  Breadcrumb,
  Space,
  Button,
  Upload,
  message,
  Table,
  InputNumber,
  Badge,
  Input,
  Form,
  Typography,
  Modal,
  Checkbox,
  Image,
  Select,
  Row,
  Col,
} from "antd";
import "./productUpload.scss";
import { Link } from 'react-router-dom';
import {
  HomeOutlined,
  UserOutlined,
  WarningOutlined,
  CheckCircleOutlined,
  UploadOutlined,
  SaveOutlined,
  CloseOutlined,
  InfoCircleOutlined,
  EditOutlined,
} from "@ant-design/icons";
import {
  uploadProductCSV,
  getImportCSVData,
  editSingleProductByCSV,
} from "../../Store/Reducers/Product/productSlice";
const { Option } = Select;
const ProductUploadContainer = () => {
  const dispatch = useDispatch();
  const { Paragraph, Text } = Typography;
  const [productCSV, SetProductCSV] = useState(null);
  const [form] = Form.useForm();
  let [productEditForm] = Form.useForm();
  const [data, setData] = useState([]);
  const {
    uploadProductCSVStatus,
    getImportData,
    getImportDataStatus,
    editSingleProductByCSVStatus,
  } = useSelector((state) => state.products);
  const UploadCSV = () => {
    const formData = new FormData();
    formData.append("csv-file", productCSV);
    dispatch(uploadProductCSV(formData));
  };

  useEffect(() => {
    if (
      uploadProductCSVStatus === "succeeded" ||
      editSingleProductByCSVStatus === "succeeded"
    ) {
      dispatch(getImportCSVData());
    }
  }, [uploadProductCSVStatus, editSingleProductByCSVStatus]);

  useEffect(() => {
    if (getImportDataStatus === "idle") {
      dispatch(getImportCSVData());
    }
  }, [getImportDataStatus]);

  useEffect(() => {
    setData(getImportData);
  }, [getImportData]);

  const [editingKey, setEditingKey] = useState("");
  const isEditing = (record) => record._id === editingKey;
  const [modalEdit, setModalEdit] = useState(false);
  const [productEditing, setProductEditing] = useState("");
  const edit = (record) => {
    productEditForm.setFieldsValue({
      brand: "",
      category: "",
      description: "",
      groupSize: "",
      image: "",
      isAddToCurrentStores: "",
      metric: "",
      price: "",
      regularPrice: "",
      pricePer100: "",
      quantity: "",
      size: "",
      type: "",
      ...record,
    });
    setEditingKey(record._id);
    setModalEdit(true);
  };

  const cancel = () => {
    setEditingKey("");
  };
  const resetEditing = () => {
    setModalEdit(false);
    setProductEditing(null);
  };
  const onFinish = (data) => {
    let row = { ...data, _id: editingKey };
    save(row);
    resetEditing();
  };
  const displayErrorTag = (isValidField, text) => {
    return isValidField ? (
      <div className="Highlighted-Red">
        <WarningOutlined /> {text}
      </div>
    ) : (
      text
    );
  };
  const save = async (row) => {
    try {
      const newData = [...data];
      const index = newData.findIndex((item) => row._id === item._id);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setData(newData);
        setEditingKey("");
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey("");
      }
      dispatch(editSingleProductByCSV(row));
    } catch (errInfo) {
      
    }
  };
  const tableColumns = [
    {
      title: "status",
      dataIndex: "status",
      fixed: "left",
      onCell: (record, index) => {
        return {
          style: {
            background: record.status === "error" ? "#FF8484" : "",
          },
        };
      },
      filters: [
        {
          text: "Success",
          value: "success",
        },
        {
          text: "Error",
          value: "error",
        },
      ],
      onFilter: (value, record) => record.status.indexOf(value) === 0,
      render: (text, record) =>
        record.status === "error" ? (
          <>
            <WarningOutlined /> {text}
          </>
        ) : (
          text
        ),
    },
    {
      title: "Brand",
      dataIndex: "brand",
      fixed: "left",
      editable: true,
      render: (text, record) => displayErrorTag(record.errorJSON.brand, text),
    },
    {
      title: "category",
      dataIndex: "category",
      editable: true,
      render: (text, record) =>
        displayErrorTag(record.errorJSON.category, text),
    },
    {
      title: "description",
      dataIndex: "description",
      editable: true,
      ellipsis: "true",
      width: "10%",
      render: (text, record) =>
        displayErrorTag(record.errorJSON.description, text),
    },
    {
      title: "groupSize",
      dataIndex: "groupSize",
      editable: true,
      render: (text, record) =>
        displayErrorTag(record.errorJSON.groupSize, text),
    },
    {
      title: "image",
      dataIndex: "image",
      width: "5%",
      render: (name, record) =>
        record.errorJSON.image ? (
          <>
            <WarningOutlined />
            <Image src={name} width={20} height={20} />
          </>
        ) : (
          <Image src={name} width={20} height={20} />
        ),
      editable: true,
    },
    {
      title: "isAddToCurrentStores",
      dataIndex: "isAddToCurrentStores",
      editable: true,
      ellipsis: "true",
      render: (text, record) =>
        record.errorJSON.isAddToCurrentStores ? (
          <div className="Highlighted-Red">
            <WarningOutlined /> {text ? "YES" : "NO"}
          </div>
        ) : text ? (
          "YES"
        ) : (
          "NO"
        ),
    },
    {
      title: "metric",
      dataIndex: "metric",
      editable: true,
      width: "5%",
      render: (text, record) => displayErrorTag(record.errorJSON.metric, text),
    },
    {
      title: "price",
      dataIndex: "price",
      editable: true,
      render: (text, record) => displayErrorTag(record.errorJSON.price, text),
    },
    {
      title: "regularPrice",
      dataIndex: "regularPrice",
      editable: true,
      ellipsis: "true",
      render: (text, record) =>
        displayErrorTag(record.errorJSON.regularPrice, text),
    },
    {
      title: "pricePer100",
      dataIndex: "pricePer100",
      editable: true,
      ellipsis: "true",
      render: (text, record) =>
        displayErrorTag(record.errorJSON.pricePer100, text),
    },
    {
      title: "quantity",
      dataIndex: "quantity",
      editable: true,
      render: (text, record) =>
        displayErrorTag(record.errorJSON.quantity, text),
    },
    {
      title: "size",
      dataIndex: "size",
      editable: true,
      render: (text, record) => displayErrorTag(record.errorJSON.size, text),
    },

    {
      title: "type",
      dataIndex: "type",
      editable: true,
      render: (text, record) => displayErrorTag(record.errorJSON.type, text),
    },

    {
      title: "Action",
      fixed: "right",
      render: (_, record) => {
        const editable = isEditing(record);
        return record.status === "error" ? (
          editable ? (
            <span>
              <Typography.Link
                onClick={() => save(record)}
                style={{
                  marginRight: 8,
                }}
              >
                <SaveOutlined style={{ fontSize: "15px", color: "#00008B" }} />
              </Typography.Link>
              <Typography.Link onClick={() => cancel()}>
                <CloseOutlined style={{ fontSize: "15px", color: "#FF0000" }} />
              </Typography.Link>
            </span>
          ) : (
            <Typography.Link
              disabled={editingKey !== ""}
              onClick={() => edit(record)}
            >
              <EditOutlined style={{ fontSize: "20px", color: "#00008B" }} />
            </Typography.Link>
          )
        ) : (
          <CheckCircleOutlined style={{ fontSize: "20px", color: "#90EE90" }} />
        );
      },
    },
  ];
  return (
    <Card
      title={
        <Space
          direction="horizontal"
          style={{ width: "100%", justifyContent: "space-between" }}
        >
          <Breadcrumb>
            <Breadcrumb.Item>
              <HomeOutlined />
            </Breadcrumb.Item>
            <Breadcrumb.Item>
            <Link to="/product">
              <UserOutlined />
              <span>Products </span>
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Upload</Breadcrumb.Item>
          </Breadcrumb>
          {data?.length > 0 && (
            <Button type="primary" onClick={() => dispatch(getImportCSVData())}>
              Refresh Status
            </Button>
          )}
        </Space>
      }
    >
      {data?.length > 0 ? (
        <Form form={form} component={false}>
          <Table
            
            bordered
            dataSource={data}
            rowKey={"_id"}
            columns={tableColumns}
            rowClassName="editable-row"
            pagination={{
              onChange: cancel,
              pageSize: 50,
            }}
            size="small"
          />
          <Table />
          <Modal
            title="Edit Product"
            visible={modalEdit}
            onCancel={() => resetEditing()}
            footer={null}
            width={1000}
          >
            <Form
              name={productEditForm}
              form={productEditForm}
              initialValues={productEditing}
              onFinish={onFinish}
              layout="vertical"
              autoComplete="off"
            >
              <Row gutter={[20, 20]}>
                <Col span={6}>
                  <Form.Item
                    name="brand"
                    label="Brand Name"
                    rules={[
                      {
                        required: true,
                        message: "Please input Brand Name",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="category"
                    label="Category"
                    rules={[
                      {
                        required: true,
                        message: "Please input Cateogory",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="description"
                    label="Description"
                    rules={[
                      {
                        required: true,
                        message: "Please input Description",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="groupSize"
                    label="Group Size"
                    rules={[
                      {
                        required: true,
                        message: "Please input Group Size",
                      },
                    ]}
                  >
                    <Select style={{ width: "100%" }}>
                      <Option value="Small"> Small </Option>
                      <Option value="Medium"> Medium </Option>
                      <Option value="Large"> Large </Option>
                      <Option value="X-Large"> X-Large </Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[20, 20]}>
                <Col span={6}>
                  <Form.Item
                    name="price"
                    label="Price"
                    rules={[
                      {
                        required: true,
                        message: "Please input Price",
                      },
                    ]}
                  >
                    <Input type="number" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="regularPrice"
                    label="Regular Price"
                    rules={[
                      {
                        required: true,
                        message: "Please input Regular Price",
                      },
                    ]}
                  >
                    <Input type="number" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="pricePer100"
                    label="Price Per 100"
                    rules={[
                      {
                        required: true,
                        message: "Please input Price Per 100",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="metric"
                    label="Metric"
                    rules={[
                      {
                        required: true,
                        message: "Please select Metric",
                      },
                    ]}
                  >
                    <Select style={{ width: "100%" }}>
                      <Option value="ml"> ml </Option>
                      <Option value="L"> L </Option>
                      <Option value="g"> g </Option>
                      <Option value="kg"> kg</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[20, 20]} style={{ marginTop: "20px" }}>
                <Col span={6}>
                  <Form.Item name="image" label="Image">
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="quantity"
                    label="Quantity"
                    rules={[
                      {
                        required: true,
                        message: "Please input Quantity",
                      },
                    ]}
                  >
                    <Input type="number" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="size"
                    label="Size"
                    rules={[
                      {
                        required: true,
                        message: "Please input Size",
                      },
                    ]}
                  >
                    <Input type="number" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="type"
                    label="Product Type"
                    rules={[
                      {
                        required: true,
                        message: "Please input Product Type",
                      },
                    ]}
                  >
                    <Select>
                      <Option value="milk"> Milk </Option>
                      <Option value="yogurt"> Yogurt </Option>
                      <Option value="cheese"> Cheese </Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[20, 20]} style={{ marginTop: "20px" }}>
                <Col>
                  <Form.Item
                    name="isAddToCurrentStores"
                    label="Add this product to all store ?"
                    valuePropName="checked"
                    tooltip={{
                      title:
                        "Whould you like to add this new product to all existing stores ?",
                      icon: <InfoCircleOutlined />,
                    }}
                  >
                    <Checkbox />
                  </Form.Item>
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
          </Modal>
        </Form>
      ) : (
        <>
          <Upload
            accept=".csv"
            maxCount={1}
            showUploadList={true}
            onChange={(fileList) => {
              SetProductCSV(fileList.file.originFileObj);
            }}
            beforeUpload={(file) => {}}
            customRequest={({ file, onSuccess, onError }) => {
              setTimeout(() => {
                onSuccess("ok");
              }, 0);
            }}
          >
            <Button icon={<UploadOutlined />}>
              Click to Upload your csv file
            </Button>
          </Upload>
          <Space style={{marginTop:'10px'}}>
          <Button
            type="primary"
          >
           <Link to="/product">Back</Link>
          </Button>
          <Button
            type="primary"
            onClick={() => {
              UploadCSV();
            }}
          >
            Submit
          </Button>
          
          </Space>
        </>
      )}
    </Card>
  );
};

export default ProductUploadContainer;
